import createPersistedState from 'vuex-persistedstate'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		authenticated:false,
    access:{},
    currentUser:{},
    selectedTab:"",
		darkMode:false,    
  },
  getters: {
    darkMode: state => {
      return state.darkMode;
    },    
  },
  mutations: {
    setDarkmode(state, mode){
        state.darkMode = mode
    },
    login: state=>state.authenticated=true,
		logout: state=>state.authenticated=false,
		setAccessLevel(state,access) 
    {
			state.access = access;
		},
		setToken(state,token) 
    {
			state.access.token = token;
		},
		setUser(state,user) 
    {
			state.currentUser = user;
		},
    setSelectedTab(state,tab)
    {
      state.selectedTab = tab;
    }
  },
  actions: {
  },
  modules: {
  },
	plugins: [
		createPersistedState({
			storage: window.localStorage,
		})
	],
})
