import axios from 'axios'

const apiClient=axios.create({
	baseURL: 'https://api2.iotx3.com',
	withCredentials: false,
	headers:{
		Accept: 'application/json',
	}
})

export default {
	login(user,password,system) {
		const body = {};		
        body.username=user;
        body.password=password;
        body.system=system;

		return apiClient.post('/api/authorise',body)
	},	
}	