<template>
	<div>
			<mainView v-if="authenticated"></mainView>
			<frontView v-else></frontView>
	</div>
</template>
<script>
	import mainView from '@/views/mainView.vue'
	import frontView from '@/views/frontView.vue'
	import data from '@/services/data.js'
	import CryptoJS from 'crypto-js';

	export default {
	components: {
		mainView,
		frontView
	},
	name: "App",
		// Bound properties declaration
		data() {
			return {
				auth: true,
			};
		},
		methods: {
			logOut(){
				this.auth=false;
			},
		},
		computed: {
			superadmin() {
				var userData=this.$store.state.user;
				var result=false;
				if (userData&&userData.superadmin)
					result=true;
				return result;
			},
			admin() {
				var userData=this.$store.state.user;
				var result=false;
				if (userData&&userData.admin)
					result=true;
				return result;
			},
			access() {
				return this.$store.state.access;
			},
			authenticated() {
				return this.$store.state.authenticated;
			},
			info()
			{
				return this.$store.state.info;
			},
			user()
			{
				return this.$store.state.user;
			},
			token()
			{
				return this.$store.state.token;
			}
		},
		created() 
		{

		this.$vuetify.theme.dark=this.$store.getters.darkMode;
/*            data.writeRecord("test","test",newRecord).then(response=>{
				console.log(response.data);
            }).catch(error=> { console.log(error)});
*/
		},
	};
</script>
<style>
</style>