import axios from 'axios'

const apiClient=axios.create({
	baseURL: 'https://api2.iotx3.com',
	withCredentials: false,
	headers:{
		Accept: 'application/json',
	}
})

export default {
	getData(index,token) {
		const body = {};		
        body.index=index;
        body.token=token;

		return apiClient.post('/api/get/index',body);
	},	
	getFieldData(index,fields,token) {
		const body = {};		
        body.index=index;
        body.selection=fields;
        body.token=token;

		return apiClient.post('/api/get/index',body);
	},	
	getFieldDataEx(index,fields,token,access) {
		const body = {};		
        body.index=index;
        body.selection=fields;
        body.token=token;
        body.access=access;

		return apiClient.post('/api/get/index',body);
	},	
    getItem(index,token,id)
    {
		const body = {};		
        body.token=token;
        body.index=index;
        body.id=id;

		return apiClient.post('/api/get/item',body);        
    },
    getItemEx(index,token,id,access)
    {
		const body = {};		
        body.token=token;
        body.index=index;
        body.id=id;
        body.access=access;

		return apiClient.post('/api/get/item',body);        
    },
	writeRecord(index, token, data) {
		const body = {};		
        body.token=token;
        body.index=index;
        body.data=data;
    
		return apiClient.post('/api/update/item',body);        
    },
    updateRecord(index, token, data) {
		const body = {};		
        body.token=token;
        body.index=index;
        body.data=data;
    
		return apiClient.post('/api/change/item',body);        
    },
    getRecords(token, index, entity){
        var epoch=new Date().getTime();
        
        config = {
            method: 'get',
            url: '/es/search',
            headers: { 
                index:index,
                token: token, 
                body:btoa(JSON.stringify({
                    query:{
                        bool:{
                            should:[
                                {match: {filter:entity}},
                            ],
                        }
                    }
                })),
                epoch:epoch
            },
            
        }

        return axios(config);    
    },
    deleteRecord(index,id,status)
    {
        config = {
            headers: {
                index:index,
                token: token, 
                id: id, //'QsrO24MBhT0H0BiYouiC',	
                epoch:epoch,
                status:status //"deleted"
            }
        };		
        return apiClient.delete('/es/index',config);    
    }

}	