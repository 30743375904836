<template>
	<div style="margin-top:5px;margin-left:10px;margin-right:10px;">
        <v-row style="margin-top:5px;">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:5px; ">
                    Email Address
                </div>
            </v-col>
            <v-col cols="9">
                <v-text-field
                    v-model="email"
                    :counter="50"
                    label="Email Address"
                    style="margin-top:-15px;"
                ></v-text-field>
            </v-col>
        </v-row>	
        <v-row style="margin-top:-30px;">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:10px; ">
                    Password
                </div>
            </v-col>
            <v-col cols="9">
                <v-text-field
                    ref="password"
                    v-model="password1"
                    :append-icon="showPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword1 ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    :counter="8"
                    label="Password"
                    placeholder="Password"
                    counter
                    style="margin-top:-5px;"
                    @click:append="showPassword1 = !showPassword1"
                />                            
            </v-col>
        </v-row>	
        <v-row style="margin-top:-35px;">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:10px; ">
                    Confirm Password
                </div>
            </v-col>
            <v-col cols="9">
                <v-text-field
                    ref="password"
                    v-model="password2"
                    :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword2 ? 'text' : 'password'"
                    :counter="8"
                    prepend-icon="mdi-lock"
                    label="Confirm Password"
                    placeholder="Password"
                    counter
                    style="margin-top:-5px;"
                    @click:append="showPassword2 = !showPassword2"
                />                            
            </v-col>
        </v-row>	
        <div style="margin-top:5px;margin-bottom:30px; height: 30px;" v-if="changesMade">
            <v-btn
                dark
                @click="saveData"
                style="margin-left:10px;margin-top:5px;float:right;background-color:LimeGreen;width:250px;"
                
            >
                <v-icon dark style="margin-right:10px;">
                    mdi-content-save
                </v-icon> Save
            </v-btn>
        </div>
        <div style="margin-top:5px;margin-bottom:30px; height: 30px;" v-else>
            <v-btn
                @click="saveData"
                style="margin-left:10px;margin-top:5px;float:right;width:250px;"
                disabled
            >
                <v-icon dark style="margin-right:10px;">
                    mdi-content-save
                </v-icon> Save
            </v-btn>
        </div>
        <div v-if="passwordMismatch||errorMessage!=''" style="background-color:red;color:white;margin-top:5px;">
            {{errorMessage}}
        </div>
    </div>
</template>

<script>
import data from '@/services/data.js'
import CryptoJS from 'crypto-js';

export default {
    name: 'userSettings',
    props:['inContact'],
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            showPassword1:false,
            showPassword2:false,
            password1:"",
            password2:"",
            access:null,
            email:"",
            origEmail:"",
            errorMessage:"",   
            loading:false,       
        };
    },
	methods: {
        async saveData()
        {
            var curData={};
            if (this.password1!=="")
            {
                curData.password=CryptoJS.SHA3(this.password1).toString(CryptoJS.enc.Base64);
            }
            if (this.email!==this.origEmail)
            {
                curData.email=this.email;
            }
            var response=await data.updateRecord("user",this.$store.state.access.token, curData);
            if (response.data&&response.data.token)
            {
                console.log(response.data);
                this.errorMessage="";
            }
            else
            {
                this.errorMessage="Error saving data";
            }

        },
        clickAccess(item)
        {
            console.log(item);
        },
        async loadContact(val)
        {
            this.loading=true;
            this.company={};
            this.companyContact="Create Primary Contact";
            this.selectedSystems=[];
            this.email=this.$store.state.currentUser.email;
            this.origEmail=this.email;
            this.loading=false;
        },        
	},
	computed: {
        changesMade()
        {
            var changed=false;

            if (this.email!=this.origEmail&&this.email!==""&&this.email.indexOf("@")>0&&this.email.indexOf(".")>0)
                changed=true;

            if (this.password1===this.password2&&this.password1!==""&&this.password1.length>=8)
                changed=true;

            return changed;
        },
        passwordMismatch()
        {
            var mismatch;

            if (this.password1&&this.password1!==""&&this.password2!=""&&this.password1!==this.password2&&!this.loading)
            {
                mismatch=true;
                this.errorMessage="Passwords do not match"
            }
            else
            {
                this.errorMessage="";
                if ((this.email&&this.email.indexOf("@")<0||this.email.indexOf(".")<0)&&!this.loading)
                {
                    this.errorMessage="Invalid email address"
                }
            }

            return mismatch;
        }
	},
    watch: {
    },
  	created() 
	{
        this.loadContact();
	},
};
</script>