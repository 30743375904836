<template>
	<div>
		<div style="background-color:#00A400;color:white; margin-top:15px;padding-left:15px;">
			User Management
		</div>
        <v-row style="margin-top:0px; margin-left:5px;">
            <div style="margin-left:5px; margin-bottom:10px;margin-top:5px;">
                <v-btn @click="editUsersDialog=true;" style="border-style: solid;border-color: gray;">Edit</v-btn>
            </div>
            <div v-if="users.length===0&&!loading" style="margin-left:10px; margin-top:10px;">
                No users setup yet
            </div>
            <div v-else style="margin-left:10px; margin-top:10px;">
                Users: {{userCount()}}
            </div>
        </v-row>

        <v-dialog fullscreen v-model="editUsersDialog">
            <v-toolbar
            dark
            style="background-color: #008400;"
            >
                <v-toolbar-title ><v-icon style="margin-right:5px;margin-top:-5px;">mdi-account-supervisor</v-icon>Users</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="editUsersDialog = false;action='';"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="px-4" style="height:auto;">
                <v-card-text>
                    <div>
                        <v-row>
                            <v-btn style="margin-top:12px;margin-left:10px;border-style: solid;border-color: white;" @click="addItem()">
                                Add
                            </v-btn>
                            <v-checkbox
                                v-model="showDeleted"
                                enabled
                                label="Show Deleted"
                                style="margin-left:30px;"  
                                v-if="this.$store.state.access.access>=2"                              
                            ></v-checkbox>
                        </v-row>
                        <v-data-table
                            style="margin-left:0px;margin-right:15px;margin-top:5px;"
                            v-model="selectUsers"
                            :headers="headers"
                            :items="filteredData"
                            single-select
                            class="elevation-1"
                        >
                            <template v-slot:item.name="{ item }">
                                <div v-if="!item.deleted">{{item.name}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.name}}</strike>
                            </template>		
                            <template v-slot:item.url="{ item }">
                                <div v-if="!item.deleted">{{item.url}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.url}}</strike>
                            </template>		
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    v-if="!item.deleted"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    v-if="!item.deleted"
                                    small
                                    @click="deleteItem(item,true)"
                                >
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                    v-else
                                    small
                                    @click="deleteItem(item,false)"
                                    style="color:red"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>		
                        </v-data-table>	
                    </div>
                    <div style="margin-top:15px;" v-if="action==='edit'||action==='new'">
                        <div v-if="passwordMismatch||errorMessage!=''" style="background-color:red;color:white;margin-top:5px;">
                            {{errorMessage}}
                        </div>
                        <v-row>
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    First Name
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="user.firstName"
                                    :counter="25"
                                    label="First Name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Last Name
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="user.lastName"
                                    :counter="25"
                                    label="Last Name"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-40px">        
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Mobile Phone Number
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="user.mobilePhone"
                                    :counter="25"
                                    label="Mobile Phone Number"
                                    type="number"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Office Phone Number
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="user.officePhone"
                                    :counter="25"
                                    label="Office Phone Number"
                                    type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-40px">
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Email Address
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="user.email"
                                    :counter="50"
                                    label="Email Address"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Access Level
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-combobox
                                    v-model="userAccess"
                                    :items="accessLevels"
                                    item-value="level"
                                    item-text="name"
                                    label="Select access level"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-40px" v-if="this.$store.state.access.access>=1">
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Branches
                                </div>
                            </v-col>
                            <v-col cols="9">
                                <v-combobox
                                    v-model="selectedBranches"
                                    :items="branches"
                                    item-value="id"
                                    item-text="name"
                                    label="Select branches for this user"
                                    multiple
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-40px" v-if="this.$store.state.access.access>=3">
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Company
                                </div>
                            </v-col>
                            <v-col cols="9">
                                <v-combobox
                                    v-model="selectedCompany"
                                    :items="companies"
                                    item-value="id"
                                    item-text="companyName"
                                    label="Select company for this user"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-40px">
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Password
                                </div>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field
                                    ref="password"
                                    v-model="password1"
                                    :append-icon="showPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="showPassword1 ? 'text' : 'password'"
                                    prepend-icon="mdi-lock"
                                    label="Password"
                                    placeholder="Password"
                                    counter
                                    required
                                    @click:append="showPassword1 = !showPassword1"
                                />                            
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-40px">
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Confirm Password
                                </div>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field
                                    ref="password"
                                    v-model="password2"
                                    :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="showPassword2 ? 'text' : 'password'"
                                    prepend-icon="mdi-lock"
                                    label="Confirm Password"
                                    placeholder="Password"
                                    counter
                                    required
                                    @click:append="showPassword2 = !showPassword2"
                                />                                                      
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:15px;">
                                <v-col cols="3">
                                </v-col>
                                <v-btn
                                    dark
                                    @click="saveData"
                                    style="margin-left:10px;margin-top:5px;float:right;background-color:LimeGreen;width:250px;"
                                    v-if="validUser&&(this.$store.state.access.id!==user.id||this.$store.state.access.access>=3)"
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-content-save
                                    </v-icon> Save
                                </v-btn>
                                <v-btn
                                    @click="saveData"
                                    style="margin-left:10px;margin-top:5px;float:right;width:250px;"
                                    disabled
                                    v-else
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-content-save
                                    </v-icon> Save
                                </v-btn>                                
                                <v-btn
                                    dark
                                    style="margin-left:10px;margin-top:5px;float:right;background-color:Tomato;width:250px;"
                                    @click="cancelChanges"
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-close
                                    </v-icon> Cancel Changes
                                </v-btn>                            
                            </v-row>  
                        </div>
                </v-card-text>
            </v-card>
        </v-dialog>

	</div>
</template>

<script>
import data from '@/services/data.js'
import contactEdit from '@/components/admin/contactEdit.vue'
import CryptoJS from 'crypto-js';

export default {
    name: 'users',
    components: {
        contactEdit,
    },
    // Bound properties declaration
    data() {
        return {
            editUsersDialog:false,
            showDeleted:false,
            selectUsers:[],
            headers:[
                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' },
                { text: 'Email', value: 'email' },
                { text: 'Actions', value: 'actions' },
            ],
            user:{},
            users:[],
            selectedBranches:[],
            branches:[],
            allBranches:[],
            companies:[],
            selectedCompany:{},
            loading:false,
            action:"",
            showPassword1:false,
            showPassword2:false,
            password1:"",
            password2:"",
            accessLevels:[
                    {level:-99,name:"Driver"},
                    {level:0,name:"User"},
                    {level:1,name:"Branch Administrator"},
                    {level:2,name:"Company Administrator"},            
                    {level:3,name:"System Administrator"} 
            ],
            errorMessage:"",
            loading:false,
            userAccess:"",
        };
    },
	methods: {
        cancelChanges()
        {
            console.log("Cancel");
            this.action="";
        },
        async saveData()
        {
            var user=this.user;
            user.access=this.userAccess.level;
            user.companyId=this.selectedCompany.id;
            if (!(this.selectedCompany&&this.selectedCompany.id)||user.companyId==="")
            {
                user.companyId=this.$store.state.access.companyId;
            }
            console.log("Company ID "+user.companyId);
            user.branches=[];
            this.selectedBranches.forEach(function(item){
                user.branches.push(item.id);
            });

            if (this.password1!=="")
            {
                user.password=CryptoJS.SHA3(this.password1).toString(CryptoJS.enc.Base64);
            }

            var response={};
            if (user.id&&user.id!=="")
            {
                console.log("Existing item "+user.id);
                response=await data.updateRecord("access",this.$store.state.access.token, user);
            }
            else
            {
                console.log("New item "+user.id);
                response=await data.writeRecord("access",this.$store.state.access.token, user);
            }
            if (response.data&&response.data.status&&response.data.status==="ok"&&response.data.token)
            {
                this.action="";
                this.$store.commit("setToken",response.data.token);

                var me=this;
                await this.loadUsers();
            }
            else 
            {
                if (response.data.result&&response.data.message&&response.data.result==="error")
                {
                    this.action=currentAction;
                    this.saveError="Error saving system"
                    console.log("logout on error editCompany");
                }
            }


        },
        addItem()
        {
            this.user={};
            this.selectedBranches=[];
            this.selectedCompany=[];
            this.password1="";
            this.password2="";
            this.action="new";
        },
        async loadUser(index)
        {
            var response=await data.getItemEx("access",this.$store.state.access.token,index,3)
            if (response.data&&response.data.data&&response.data.token)
            {
                this.user=response.data.data;
                this.$store.commit("setToken",response.data.token);
            }
        },
        async editItem(item)
        {
            await this.loadUser(item.id);
            this.selectedBranches=[];
            this.selectedCompany=[];
            this.password1="";
            this.password2="";
            var me=this;
            this.accessLevels.forEach(function(item)
                {
                    if (item.level===me.user.access)
                        me.userAccess=item;
                });
            if (this.$store.state.access.access>=3)
            {
                this.branches=[];
                this.companies.forEach(function(item)
                {
                    if (item.id===me.user.companyId)
                        me.selectedCompany=item;
                });
                this.allBranches.forEach(function(item){
                    if (item.companyId===me.user.companyId&&!item.deleted)
                        me.branches.push(item);

                });
            }
            this.allBranches.forEach(function(item){
                if (me.user.branches)
                {
                    me.user.branches.forEach(function(branch)
                    {
                        if (branch===item.id&&!item.deleted)
                        {
                            me.selectedBranches.push(item);
                        }
                    });
                }
            });
            this.action="edit";
        },
        deleteItem()
        {

        },
        processUser(details)
        {
            console.log(details);
            this.setContactDialog=false;
        },
        userCount()
        {
            var users=0;
            var me=this;

            this.users.forEach(function(item){
                    if (!item.deleted&&item.access<=me.$store.state.access.access)
                        users++;
                });

            return users;
        },
        async loadUsers()
        {
            var responseSystems=await data.getData("access",this.$store.state.access.token);
            if (responseSystems.data&&responseSystems.data.data&&responseSystems.data.token)
            {
                this.users=responseSystems.data.data;
                this.$store.commit("setToken",responseSystems.data.token);
            }
            else
            {
                if (responseSystems.data.result&&responseSystems.data.message&&responseSystems.data.result==="error")
                {
                    console.log("logout on error - users");
                    this.$emit("logoutOnError");
                }
            }
            
        },
        async loadBranches()
        {
            var responseSystems=await data.getData("branches",this.$store.state.access.token);
            if (responseSystems.data&&responseSystems.data.data&&responseSystems.data.token)
            {
                var me=this;
                this.allBranches=responseSystems.data.data;
                this.allBranches.forEach(function(item){
                    if (!item.deleted)
                        me.branches.push(item);
                });
                this.$store.commit("setToken",responseSystems.data.token);
            }
            else
            {
                if (responseSystems.data.result&&responseSystems.data.message&&responseSystems.data.result==="error")
                {
                    console.log("logout on error editCompany");
                    this.$emit("logoutOnError");
                }
            }
            
        },
        async loadCompanies()
        {
            console.log("load company data");
            var response = await data.getData("company",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                this.companies=response.data.data;
                
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.companies=[];
                        console.log("logout on error Company");
                        this.$emit("logoutOnError");
                    }
            }
        }
	},
	computed: {
        filteredData: function()
        {
            var data=this.users;

            if (!this.showDeleted)
            {
                data=[];
                var me=this;
                this.users.forEach(function(item){
                    if (!item.deleted&&item.access<=me.$store.state.access.access)
                        data.push(item);
                });
            }

            return data;
        },
        validUser()
        {
            var valid=true;

            if (this.errorMessage!=="") 
            {
                valid=false;
                console.log("Error message")
            }

            if (!this.user.firstName||this.user.firstName==="")
            {
                valid=false;
                console.log("First name")
            }

            if (!this.userAccess||!(this.userAccess.level>=0||this.userAccess.level===-99)) 
            {
                valid=false;
                console.log("Access level")
            }

            return valid;
        },
        passwordMismatch()
        {
            var mismatch=true;

            if (this.password1&&this.password1!==""&&this.password2!=""&&this.password1!==this.password2&&!this.loading)
            {
                this.errorMessage="Passwords do not match"
            }
            else
            {
                this.errorMessage="";
                if ((this.user.email&&this.user.email.indexOf("@")<0||this.user.email&&this.user.email.indexOf(".")<0)&&!this.loading)
                {
                    this.errorMessage="Invalid email address"
                }
                else
                {
                    mismatch=false;
                }
            }

            return mismatch;
        }

	},
  	async created() 
	{
        this.loading=true;
        await this.loadUsers();
        await this.loadBranches(); 
        if (this.$store.state.access.access>=3)
        {
            await this.loadCompanies();               
        }
        this.loading=false;
	},
};
</script>
