<template>
	<div>
        <div style="background-color:#008400;color:white; margin-top:0px;padding-left:10px;">
            System Settings
        </div>
        <company @logoutOnError="errorLogout"></company>
        <systems @logoutOnError="errorLogout"></systems>
	</div>
</template>

<script>

import company from '@/components/admin/company.vue'
import systems from '@/components/admin/systems.vue'
export default {
    name: 'superAdminView',
    components: {
        company,
        systems
    },
    // Bound properties declaration
    data() {
        return {
        };
    },
	methods: {
        errorLogout()
        {
            console.log("Logout on error superadminview");
            this.$emit("logoutOnError");
        }
	},
	computed: {
	},
  	created() 
	{
	},
};
</script>
