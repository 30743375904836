<template>
	<div style="margin-top:5px;">
		<v-data-table
            style="margin-left:5px;margin-right:15px"
			v-model="contact"
			:headers="headers"
			:items="contacts"
			item-key="id"
			single-select
        >
            <template v-slot:item.companyName="{ item }">
                <td v-if="item.deleted===true" style="color:red;">{{ item.companyName }}</td>
                <td v-else>{{ item.companyName }}</td>
            </template>		
            <template v-slot:item.actions="{ item }">
                <v-btn
                    small
                    class="mr-2"
                    @click="setItem(item)"
                    v-if="item.id!==accessId"
                >
                    Set
                </v-btn>
                <div v-else>
                    Set As Company Contact
                </div>
            </template>		
		</v-data-table>	
    </div>
</template>

<script>
import data from '@/services/data.js'

export default {
    name: 'contactSelect',
    props:['accessId'],
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            headers: [
                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' },
                { text: 'Email Address', value: 'email' },
    			{ text: 'Set Contact', value: 'actions', sortable: false }
            ],
            accessLevels:[
                    {level:0,name:"User"},
                    {level:1,name:"Branch Administrator"},
                    {level:2,name:"Company Administrator"}            
            ],
            contacts:[

            ],
            contact:[],
        };
    },
	methods: {
        clickAccess(item)
        {
            console.log(item);
        },
        setItem(item)
        {
            this.$emit('updatedContact',item);
        }
	},
	computed: {
	},
    watch: {
    },
  	created() 
	{
        data.getFieldDataEx("access","id,firstName,lastName,email",this.$store.state.access.token,1)
                .then(response=>{
                        if (response.data&&response.data.data&&response.data.token)
                        {
                            this.contacts=response.data.data;
                            
                            this.$store.commit("setToken",response.data.token);
                        }
                        else 
                        {
                            if (response.data.result&&response.data.message&&response.data.result==="error")
                                {
                                    console.log("logout on error Company");
                                    this.$emit("logoutOnError");
                                }
                        }
                    })
                .catch(error=> { 
                        console.log(error)
                    });        
	},
};
</script>