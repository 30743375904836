<template>
	<div>
		<div style="background-color:#00A400;color:white; margin-top:15px;padding-left:15px;">
			Branches
		</div>
        <v-row style="margin-top:0px; margin-left:5px;">
            <div style="margin-left:5px; margin-bottom:10px;margin-top:5px;">
                <v-btn @click="editSystemsDialog=true;" style="border-style: solid;border-color: grey;">Edit</v-btn>
            </div>
            <div v-if="branchCount()===0" style="margin-left:10px; margin-top:10px;">
                No branches setup yet
            </div>
            <div v-else style="margin-left:10px; margin-top:10px;">
                Branches: {{branchCount()}}
            </div>
        </v-row>
        <v-dialog fullscreen v-model="editSystemsDialog">
            <v-toolbar
            dark
            style="background-color: #008400;"
            >
                <v-toolbar-title ><v-icon style="margin-right:5px;margin-top:-5px;">mdi-factory</v-icon>Branches</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                            icon
                            dark
                            @click="editSystemsDialog = false; cancelChanges();"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
            </v-toolbar>
            <v-card class="px-4" @keyup.enter.native="validate" style="height:auto;">
                <v-card-text>
                    <div>
                        <v-row>
                            <v-btn style="margin-top:12px;margin-left:10px;border-style: solid;border-color: white;" @click="addItem()">
                                Add
                            </v-btn>
                            <v-checkbox
                                v-model="showDeleted"
                                enabled
                                label="Show Deleted"
                                style="margin-left:30px;"  
                                v-if="this.$store.state.access.access>=2"                              
                            ></v-checkbox>
                        </v-row>
                        
                        <v-data-table
                            style="margin-left:0px;margin-right:15px;margin-top:5px;"
                            v-model="selectBranches"
                            :headers="headers"
                            :items="filteredData"
                            single-select
                            class="elevation-1"
                        >
                            <template v-slot:item.systems="{ item }">
                                <div v-if="!item.deleted">{{getSystems(item.systems)}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{getSystems(item.systems)}}</strike>
                            </template>		
                            <template v-slot:item.name="{ item }">
                                <div v-if="!item.deleted">{{item.name}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.name}}</strike>
                            </template>		
                            <template v-slot:item.url="{ item }">
                                <div v-if="!item.deleted">{{item.url}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.url}}</strike>
                            </template>		
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    v-if="!item.deleted"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    v-if="!item.deleted"
                                    small
                                    @click="deleteItem(item,true)"
                                >
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                    v-else
                                    small
                                    @click="deleteItem(item,false)"
                                    style="color:red"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>		
                        </v-data-table>	

                        <div v-if="action==='edit'||action==='new'">
                            <v-row>
                                <v-col cols="3">
                                    <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                        Branch Name
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                        v-model="branch.name"
                                        label="Branch Name"
                                        single-line
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-40px" v-if="this.$store.state.access.access>=3">
                            <v-col cols="3">
                                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:20px;">
                                    Company
                                </div>
                            </v-col>
                            <v-col cols="9">
                                <v-combobox
                                    v-model="selectedCompany"
                                    :items="companies"
                                    item-value="id"
                                    item-text="companyName"
                                    label="Select company for this user"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                            <v-row style="margin-top:-50px">
                                <v-col cols="3">
                                    <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:15px;">
                                        Systems
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-combobox
                                        v-model="selectedSystems"
                                        :items="systems"
                                        item-value="id"
                                        item-text="system"
                                        label="Select systems that this branch is available"
                                        multiple
                                    ></v-combobox>
                                </v-col>
                            </v-row>        
                            <v-row style="margin-top:-45px;">
                                <v-col cols="3">
                                    <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:15px;">
                                        Branch Address
                                    </div>
                                </v-col>
                                <v-col cols="9" style="margin-top:15px;">
                                    <addressEdit @updatedAddress="updatedAddress" :inAddress="branch.address"></addressEdit>
                                </v-col>
                            </v-row> 
                            <v-row style="margin-top:20px;">
                                <v-col cols="3">
                                    <div style="background-color:#00A400;color:white; padding-left:15px; margin-top:0px;">
                                        Branch Data
                                    </div>
                                </v-col>
                                <v-col cols="9" style="margin-top:-10px; ">
                                    <v-btn
                                        dark
                                        @click="addBranchData"
                                        style="margin-top:5px;  width:250px;background-color: dodgerblue;"
                                    >
                                        <v-icon dark style="margin-right:10px;">
                                            mdi-plus
                                        </v-icon> Add Data
                                    </v-btn>
                                    <v-data-table
                                        style="margin-top:10px;margin-left:0px;margin-right:5px;border-style:groove;"
                                        v-model="selectedData"
                                        :headers="headersData"
                                        :items="branchData"
                                        item-key="index"
                                        single-select
                                        class="elevation-1"
                                        :hide-default-footer="hideFooter"
                                        @click:row="clickData"
                                    >
                                    </v-data-table>	
                                </v-col>
                            </v-row>  
                            <v-row style="margin-top:15px;">
                                <v-col cols="3">
                                </v-col>
                                <v-btn
                                    dark
                                    @click="saveData"
                                    style="margin-left:10px;margin-top:5px;float:right;background-color:LimeGreen;width:250px;"
                                    v-if="branch.name&&branch.name!==''&&selectedSystems.length>0"
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-content-save
                                    </v-icon> Save
                                </v-btn>
                                <v-btn
                                    @click="saveData"
                                    style="margin-left:10px;margin-top:5px;float:right;width:250px;"
                                    disabled
                                    v-else
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-content-save
                                    </v-icon> Save
                                </v-btn>                                
                                <v-btn
                                    dark
                                    style="margin-left:10px;margin-top:5px;float:right;background-color:Tomato;width:250px;"
                                    @click="cancelChanges"
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-close
                                    </v-icon> Cancel Changes
                                </v-btn>                            
                            </v-row>                                           
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="branchDataDialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-card>
                <v-toolbar
                    color="#008400"
                    dark
                    v-if="existingBranchData"
                >Edit Data</v-toolbar>
                <v-toolbar
                    color="#008400"
                    dark
                    v-else
                >Add Data</v-toolbar>
                <v-card-text>
                    <div>
                        <v-row style="margin-top:0px;">
                            <v-col cols=5>
                                <v-text-field
                                    v-model="branchDataItem.id"
                                    :counter="3"
                                    label="ID"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>	
                        <v-row style="margin-top:-35px;">
                            <v-col cols=12>
                                <v-text-field
                                    v-model="branchDataItem.name"
                                    :counter="50"
                                    label="Name"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>	
                        <v-row style="margin-top:-35px;">
                            <v-col cols=12>
                                <v-text-field
                                    v-model="branchDataItem.value"
                                    :counter="50"
                                    label="Value"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>	
                        <div style="margin-top:20px;height: 30px;">
                            <v-btn
                                text
                                @click="closeDataButton"
                                style="border-style: solid;float: right;"
                            >
                                Close
                            </v-btn>
                            <v-btn
                                text
                                @click="deleteDataButton"
                                style="border-style: solid;float: left; background-color: red; margin-right:15px;"
                                v-if="branchDataItem&&existingBranchData&&branchDataItem.source!='company'"
                            >
                                Delete
                            </v-btn>
                            <div v-else>
                                Company defined data, edit company to change this.
                            </div>
                            <v-btn
                                text
                                @click="addDataButton"
                                style="border-style: solid;float: left; background-color: green; margin-right:15px;"
                                v-if="!existingBranchData&&this.branchDataItem!=={}"
                            >
                                Add
                            </v-btn>                            
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>         
	</div>
</template>

<script>
import data from '@/services/data.js'
import addressEdit from '@/components/addressEdit.vue'
export default {
    name: 'branches',
    components: {
        addressEdit,
    },
    // Bound properties declaration
    data() {
        return {
            editSystemsDialog:false,
            branchDataDialog:false,
            selectBranches:[],
            companies:[],
            selectedCompany:{},
            branch:{},
            branches:[],
            branchData:[],
            branchDataIndex:0,
            branchDataItem:{},
            branchDataOriginal:{},
            headers: [
                { text: 'Branch Name', value: 'name' },
                { text: 'Actions', value: 'actions' },
            ],
            headersData: [
                { text: 'ID', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Value', value: 'value' },
            ],
            action:'',
            showDeleted:false,
            systems:[],
            selectedSystems:[],
            selectedData:[],
            existingBranchData:false,
            hideFooter:true,
            company:{},
        };
    },
	methods: {
        clickData(data)
        {
            this.action="edit";
            this.existingBranchData=true;
            this.branchDataItem=data;
            this.branchDataOriginal=data;
            this.branchDataDialog=true;
            console.log(this.branchDataOriginal);
        },
        addBranchData()
        {
            this.branchDataItem={};
            this.existingBranchData=false;
            this.branchDataDialog=true;            
        },
        addDataButton()
        {
            if (this.branchDataItem.id&&this.branchDataItem.id!=="")
            {
                this.branchDataIndex++;
                this.branchDataItem.index=this.branchDataIndex;
                this.branchData.push(this.branchDataItem);

                this.branch.data=this.branchData;

                this.branchDataDialog=false;
            }
        },
        closeDataButton()
        {
            this.branchDataDialog=false;
        },
        deleteDataButton()
        {
            var updatedBranchData=[];
            var me=this;
            this.branchData.forEach(function(item){
                if (item.index!==me.branchDataItem.index)
                {
                    updatedBranchData.push(item);
                }
            });
            this.branch.data=updatedBranchData;
            this.branchData=this.branch.data;
            this.branchDataDialog=false;
        },
        selected()
        {

        },
        cancelChanges()
        {
            this.action="";
            this.branch={};
        },
        updatedAddress(address)
        {
            console.log(address);
            this.branch.address=address;
        },
        async loadBranch(index)
        {
            var response=await data.getItemEx("branches",this.$store.state.access.token,index,3)
            if (response.data&&response.data.data&&response.data.token)
            {
                this.branch=response.data.data;
                this.selectedSystems=this.branch.systems;

                var me=this;
                var systems=[];
                if (this.branch.systems)
                {
                    this.branch.systems.forEach(function(item)
                    {
                        for (var i=0;i<me.systems.length;i++)
                        {
                            if (item===me.systems[i].id)
                            {
                                systems.push(me.systems[i]);
                            }
                        }
                    });                        
                }
                console.log(this.branch);

                this.companies.forEach(function(item){
                if (me.branch.companyId===item.id)
                    me.selectedCompany=item;
                });
                this.selectedSystems=systems;
            }
        },
        async editItem(item)
        {
            this.branch={};
            console.log("load branch "+item.id);
            await this.loadBranch(item.id);
            if (this.branch.companyId==="")
            {
                this.branch.companyId=this.company.id;
                var me=this;
                this.companies.forEach(function(item){
                if (me.branch.companyId===item.id)
                    me.selectedCompany=item;
                });

                console.log(this.branch);
                console.log(this.company.id);
                console.log(this.branch.companyId);
            }
            var me=this;

            this.branchData=[];
            //load company data
            for (var i=0;i<this.company.businessId.length;i++)
            {
                this.branchDataIndex++;

                var newItem={};
                newItem.id=this.company.businessId[i].abbreviation;
                newItem.name=this.company.businessId[i].name;
                newItem.value=this.company.businessId[i].value;
                newItem.source="company";

                this.branchData.push(newItem);

            }

            this.branch.data.forEach(function(item){
                me.branchData.push(item);
            });

            this.branch.data=this.branchData;

            this.action="edit";
        },
        async deleteItem(item, deleted)
        {
            console.log("Delete");
            this.branch={};
            this.branch.id=item.id;
            this.branch.name=item.name;
            this.branch.systems=item.systems;
            this.branch.address={};
            this.branch.address.country=item["address.country"];
            this.branch.address.postcode=item["address.postcode"];
            this.branch.address.state=item["address.state"];
            this.branch.address.street=item["address.street"];
            this.branch.address.suburb=item["address.suburb"];
            this.branch.data=item.data;
            this.branch.companyId=item.companyId;
            var me=this;
            this.companies.forEach(function(item){
                if (me.branch.companyId===item.id)
                    me.selectedCompany=item;
            });
            this.selectedSystems=item.systems;
            this.branchData=item.data;

            this.branch.deleted=deleted;
            console.log(this.branch);

            await this.saveData();
        },
        addItem()
        {
            this.branch={};
            this.branch.name="";
            this.branch.systems=[];
            this.branch.deleted=false;
            this.branch.data=[];
            this.branchData=[];
            this.branchDataIndex=0;
            this.selectedSystems=[];
            this.branch.companyId=this.company.id;

            var me=this;
            this.companies.forEach(function(item){
                if (me.branch.companyId===item.id)
                    me.selectedCompany=item;
            });

            //load company address
            this.branch.address=this.company.address;

            var me=this;
            //load company data
            for (var i=0;i<this.company.businessId.length;i++)
            {
                this.branchDataIndex++;

                var newItem={};
                newItem.id=this.company.businessId[i].abbreviation;
                newItem.name=this.company.businessId[i].name;
                newItem.value=this.company.businessId[i].value;
                newItem.source="company";

                this.branchData.push(newItem);

            }
            this.branch.data=this.branchData;

            this.action='new';
        },
        async saveData()
        {
            var currentAction=this.action;
            this.action="";

            var branch=this.branch;
            if (this.selectedCompany&&this.selectedCompany.id)
                this.branch.companyId=this.selectedCompany.id;  
            var systems=[];
            this.selectedSystems.forEach(function(item)
            {
                systems.push(item.id);
            });
            branch.systems=systems;

            var branchData=[];
            branch.data.forEach(function(item)
            {
                if (!(item.source&&item.source==="company"))
                {
                    branchData.push(item);
                }

            });
            branch.data=branchData;

            console.log(branch);

            try
            {
                var response=await data.writeRecord("branches",this.$store.state.access.token, branch);
                if (response.data&&response.data.data&&response.data.token)
                {
                    console.log(response.data);
                    this.$store.commit("setToken",response.data.token);

                    this.loadBranches();
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.action=currentAction;
                        this.saveError="Error saving system"
                        console.log("logout on error editCompany");
                    }
                }
            }
            catch(error)
            {
                this.action=currentAction;
                this.saveError="Error saving system"
                console.log(error)
            }
        },
        getSystems(systems)
        {
            var result="";

            if (systems)
            {
                for (var i=0;i<systems.length;i++)
                {
                    if (result!=="") result+=", ";
                    console.log(systems[i]);
                    result+=systems[i].system;
                }
            }

            return result;
        },
        async loadBranches()
        {
            var responseSystems=await data.getData("branches",this.$store.state.access.token);
            console.log(responseSystems);
            if (responseSystems.data&&responseSystems.data.data&&responseSystems.data.token)
            {
                this.branches=responseSystems.data.data;
                console.log(this.branches);
                var me=this;
                this.branches.forEach(function(branch){
                    var systems=[];
                    console.log(branch);
                    if (typeof branch.systems==="string")
                    {
                        for (var i=0;i<me.systems.length;i++)
                            {
                                if (branch.systems===me.systems[i].id)
                                {
                                    systems.push(me.systems[i]);
                                }
                            }
                    }
                    else
                    {
                        if (branch.systems)
                        {
                            branch.systems.forEach(function(item)
                            {
                                for (var i=0;i<me.systems.length;i++)
                                {
                                    if (item===me.systems[i].id)
                                    {
                                        systems.push(me.systems[i]);
                                    }
                                }
                            });                        
                        }
                    }
                    branch.systems=systems;
                    console.log(me.company);

                    if (!(branch.data))
                        branch.data=[];
                    for (var i=0;i<me.company.businessId.length;i++)
                    {
                        var newItem={};
                        newItem.id=me.company.businessId[i].abbreviation;
                        newItem.name=me.company.businessId[i].name;
                        newItem.value=me.company.businessId[i].value;
                        newItem.source="company";

                        branch.data.push(newItem);

                    }
                });
                this.$store.commit("setToken",responseSystems.data.token);
            }
            else
            {
                if (responseSystems.data.result&&responseSystems.data.message&&responseSystems.data.result==="error")
                {
                    console.log("logout on error editCompany");
                    this.$emit("logoutOnError");
                }
            }
            
        },
        async loadCompany(val)
        {
            var systems=[];
            this.company={};
            this.companyContact="Create Primary Contact";
            this.selectedSystems=[];
            if (val!=="")
            {
                var responseSystems=await data.getData("systems",this.$store.state.access.token);
                if (responseSystems.data&&responseSystems.data.data&&responseSystems.data.token)
                {
                    systems=responseSystems.data.data;
                    this.$store.commit("setToken",responseSystems.data.token);
                }
                else
                {
                    if (responseSystems.data.result&&responseSystems.data.message&&responseSystems.data.result==="error")
                    {
                        console.log("logout on error editCompany");
                        this.$emit("logoutOnError");
                    }
                }

                var response=await data.getItemEx("company",this.$store.state.access.token,val,3)
                console.log(response);
                if (response.data&&response.data.data&&response.data.token)
                {
                    this.company=response.data.data;
                    this.companyContact=this.company.contactName;                            
                    this.validContact=true;

                    var me=this;
                    this.systems=[];

                    this.$store.commit("setToken",response.data.token);
                    var companySystems=this.company.systems;
                    var me=this;
                    systems.forEach(function(item){
                        for (var i=0;i<companySystems.length;i++)
                        {
                            if (item.id===companySystems[i]&&!item.deleted)
                            {
                                me.systems.push(item);
                            }
                        }
                    });
                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        console.log("logout on error editCompany");
                        this.$emit("logoutOnError");
                    }
                }
            }
            else
            {
                this.validContact=false;
            }
        },        
        branchCount: function()
        {
            var branchCnt=0;
            this.branches.forEach(function(item)
            {
                if (!item.deleted) branchCnt++;
            });
            
            return branchCnt;
        },
        async loadCompanies()
        {
            console.log("load company data");
            var response = await data.getData("company",this.$store.state.access.token);
            if (response.data&&response.data.data&&response.data.token)
            {
                this.companies=response.data.data;
                
                this.$store.commit("setToken",response.data.token);
            }
            else 
            {
                if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        this.companies=[];
                        console.log("logout on error Company");
                        this.$emit("logoutOnError");
                    }
            }
        },
	},
	computed: {
        filteredData: function()
        {
            var data=this.branches;

            if (!this.showDeleted)
            {
                data=[];
                this.branches.forEach(function(item){
                    if (!item.deleted)
                        data.push(item);
                });
            }
            console.log(data);

            return data;
        },
	},
  	async created() 
	{
        await this.loadCompany(this.$store.state.access.companyId);
        this.loadBranches();
        if (this.$store.state.access.access>=3)
        {
            await this.loadCompanies();               
        }
	},
};
</script>
