<template>
	<div style="margin-top:5px;">
        <v-row style="margin-top:0px;">
            <v-col cols=6>
                <v-text-field
                    v-model="firstName"
                    :counter="25"
                    label="First Name"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=6>
                <v-text-field
                    v-model="lastName"
                    :counter="25"
                    label="Last Name"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row style="margin-top:-35px;">
            <v-col cols=3>
                <v-text-field
                    v-model="mobilePhone"
                    :counter="18"
                    label="Mobile Phone Number"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=3>
                <v-text-field
                    v-model="officePhone"
                    :counter="18"
                    label="Office Phone Number"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols=6>
                <v-text-field
                    v-model="email"
                    :counter="50"
                    label="Email Address"
                    required
                ></v-text-field>
            </v-col>
        </v-row>	
        <v-row style="margin-top:-35px;">
            <v-col cols=12>
                <v-combobox
                    v-model="access"
                    :items="accessLevels"
                    item-value="level"
                    item-text="name"
                    label="Select access level"
                    @click:row="clickAccess"
                ></v-combobox>
            </v-col>
        </v-row>	
        <v-row style="margin-top:-35px;">
            <v-col cols=12>
                <v-text-field
                    ref="password"
                    v-model="password1"
                    :append-icon="showPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword1 ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    placeholder="Password"
                    counter
                    required
                    @click:append="showPassword1 = !showPassword1"
                />                            
            </v-col>
        </v-row>
        <v-row style="margin-top:-35px;">
            <v-col cols=12>
                <v-text-field
                    ref="password"
                    v-model="password2"
                    :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword2 ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Confirm Password"
                    placeholder="Password"
                    counter
                    required
                    @click:append="showPassword2 = !showPassword2"
                />                            
            </v-col>
        </v-row>    
        <div style="margin-top:20px;height: 30px;">
            <v-btn
                text
                @click="closeButton"
                style="border-style: solid;float: right;"
            >
                Close
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'editContact',
    props:['inContact'],
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            showPassword1:false,
            showPassword2:false,
            password1:"",
            password2:"",
            access:null,
            email:"",
            companyId:"",
            deleted:false,
            id:"",
            mobilePhone:"",
            officePhone:"",
            firstName:"",
            lastName:"",
            accessLevels:[
                    {level:0,name:"User"},
                    {level:1,name:"Branch Administrator"},
                    {level:2,name:"Company Administrator"},            
                    {level:3,name:"System Administrator"} 
            ],
        };
    },
	methods: {
        clickAccess(item)
        {
            console.log(item);
        },
        loadContact(val)
        {
            if (val&&val.firstName&&val.firstName!=="")
            {
                this.firstName=val.firstName;
                this.lastName=val.lastName;
                this.mobilePhone=val.mobilePhone;
                this.officePhone=val.mobileNumber;
                this.email=val.email;
                var me=this;
                this.accessLevels.forEach(function(item)
                {
                    if (item.level===val.access)
                        me.access=item;
                });
                this.companyId=val.companyId;
                this.deleted=val.deleted;
                this.id=val.id;
            }
            else
            {
                this.firstName="";
                this.lastName="";
                this.mobilePhone="";
                this.officePhone="";
                this.email="";
                this.access="";
                this.companyId="";
                this.deleted=false;
                this.id="";
            }
        },
        closeButton()
        {            
            var contact={};
            if (this.firstName) contact.firstName=this.firstName; else contact.firstName="";
            if (this.lastName) contact.lastName=this.lastName; else contact.lastName=this.lastName;
            if (this.mobilePhone) contact.mobilePhone=this.mobilePhone; else contact.mobilePhone=null;
            if (this.officePhone) contact.officePhone=this.officePhone; else contact.officePhone=null;
            if (this.email) contact.email=this.email; else contact.email="";
            if (this.access) contact.access=this.access.level; else contact.access=null;
            if (this.companyId) contact.companyId=this.companyId; else contact.companyId=null;
            if (this.deleted) contact.deleted=this.deleted; else contact.deleted=null;
            if (this.id) contact.id=this.id; else contact.id=null;
            if (this.password1===this.password2&&this.password1!=="")
            {
                contact.password=this.password1;
            }
            else
                contact.password="";

            this.$emit('contact',contact);
        }

	},
	computed: {
	},
    watch: {
        inContact: function(val) 
        {
            this.loadContact(val);
        }

    },
  	created() 
	{
        console.log(this.inContact);
        if (this.inContact&&this.inContact.firstName)
            this.loadContact(this.inContact);
	},
};
</script>