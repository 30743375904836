<template>
	<div>
        <v-row>
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; margin-top:25px;padding-left:15px;">
                    Company Name
                </div>
            </v-col>
            <v-col cols="9">
                <v-text-field
                    v-model="company.companyName"
                    label="Company Name"
                    single-line
                    enabled
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row style="margin-top:-50px">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; margin-top:25px;padding-left:15px;">
                    Company Logo
                </div>
            </v-col>
            <v-col cols="9">
                <v-text-field
                    v-model="company.companyLogo"
                    label="Company Logo URL"
                    single-line
                    enabled
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row style="margin-top:-30px">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:5px; ">
                    Company Address
                </div>
            </v-col>
            <v-col cols="9" >
                <addressEdit @updatedAddress="updatedAddress" :inAddress="company.address"></addressEdit>
            </v-col>
        </v-row>        
        <v-row>
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:5px; ">
                    Company Contact
                </div>
            </v-col>
            <v-col cols="9" @click="setContact">
                <v-btn
                    dark
                    style="margin-top:0px;  width:250px;background-color: dodgerblue;"
                >
                    <v-icon dark style="margin-right:10px;">
                        mdi-account-circle
                    </v-icon> Set Company Contact
                </v-btn>
                
                <b style="font-size: medium;margin-top:12px;margin-left:10px;">{{companyContact}}</b>
            </v-col>
        </v-row>        
        <v-row style="margin-top:-5px">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:15px;">
                    Active Systems
                </div>
            </v-col>
            <v-col cols="9">
                <v-combobox
                    v-model="selectedSystems"
                    :items="systems"
                    item-value="id"
                    item-text="system"
                    label="Select systems available for this company"
                    multiple
                ></v-combobox>
            </v-col>
        </v-row>        
        <v-row style="margin-top:-35px">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px;margin-top:15px;">
                    Maximum Access Level
                </div>
            </v-col>
            <v-col cols="9">
                <v-combobox
                    v-model="company.access"
                    :items="accessLevels"
                    item-value="level"
                    item-text="name"
                    label="Select maximum access level for this company"
                    @click:row="clickAccess"
                ></v-combobox>
            </v-col>
        </v-row>        
        <v-row style="margin-top:-25px;">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; padding-left:15px; margin-top:0px;">
                    Company Data
                </div>
            </v-col>
            <v-col cols="9" style="margin-top:-10px;">
                <v-btn
                    dark
                    @click="addCompanyData"
                    style="margin-top:5px;  width:250px;background-color: dodgerblue;"
                >
                    <v-icon dark style="margin-right:10px;">
                        mdi-plus
                    </v-icon> Add Data
                </v-btn>
                <v-data-table
                    style="margin-top:10px;margin-left:0px;margin-right:5px;border-style:groove;"
                    v-model="selected"
                    :headers="headers"
                    :items="company.businessId"
                    item-key="_id"
                    single-select
                    class="elevation-1"
                    :search="search"
                    :hide-default-footer="hideFooter"
                    @click:row="clickData"
                >
                </v-data-table>	
            </v-col>
        </v-row>                
        <v-row style="margin-top:10px;">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; margin-top:0px;padding-left:15px;margin-top:-2px;">
                    Save Changes
                </div>
            </v-col>
            <v-btn
                dark
                @click="saveData"
                style="margin-left:10px;margin-top:5px;float:right;background-color:LimeGreen;width:250px;"
                v-if="requiredInfo()===''"
            >
                <v-icon dark style="margin-right:10px;">
                    mdi-content-save
                </v-icon> Save
            </v-btn>
            <div v-else style="margin-top:10px; margin-left:10px;">
                Complete details to enable the save button {{requiredInfo()}}
            </div>
        </v-row>
        <v-row style="margin-top:20px;">
            <v-col cols="3">
                <div style="background-color:#00A400;color:white; margin-top:0px;padding-left:15px;margin-top:-2px;">
                    Cancel Changes
                </div>
            </v-col>
            <v-btn
                dark
                @click="$emit('closeDialog')"
                style="margin-left:10px;margin-top:5px;float:right;background-color:Tomato;width:250px;"
            >
                <v-icon dark style="margin-right:10px;">
                    mdi-close
                </v-icon> Cancel Changes
            </v-btn>
        </v-row>
        <v-dialog
            v-model="companyDataDialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-card>
                <v-toolbar
                    color="#008400"
                    dark
                    v-if="existingCompanyData"
                >Edit Company Data</v-toolbar>
                <v-toolbar
                    color="#008400"
                    dark
                    v-else
                >Add Company Data</v-toolbar>
                <v-card-text>
                    <div>
                        <v-row style="margin-top:0px;">
                            <v-col cols=5>
                                <v-text-field
                                    v-model="companyDataItem.abbreviation"
                                    :counter="3"
                                    label="Abbreviation"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>	
                        <v-row style="margin-top:-35px;">
                            <v-col cols=12>
                                <v-text-field
                                    v-model="companyDataItem.name"
                                    :counter="50"
                                    label="Name"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>	
                        <v-row style="margin-top:-35px;">
                            <v-col cols=12>
                                <v-text-field
                                    v-model="companyDataItem.value"
                                    :counter="50"
                                    label="Value"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>	
                        <div style="margin-top:20px;height: 30px;">
                            <v-btn
                                text
                                @click="closeRdButton"
                                style="border-style: solid;float: right;"
                            >
                                Close
                            </v-btn>
                            <v-btn
                                text
                                @click="deleteRdButton"
                                style="border-style: solid;float: left; background-color: red; margin-right:15px;"
                                v-if="companyDataItem&&existingCompanyData"
                            >
                                Delete
                            </v-btn>
                            <v-btn
                                text
                                @click="addRdButton"
                                style="border-style: solid;float: left; background-color: green; margin-right:15px;"
                                v-if="!existingCompanyData&&this.companyDataItem!=={}"
                            >
                                Add
                            </v-btn>                            
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>   
        <v-dialog
            v-model="setContactDialog"
            transition="dialog-top-transition"
            max-width="800"
        >
            <v-card v-if="company.id">
                <v-toolbar
                    color="#008400"
                    dark
                    
                >Set Contact</v-toolbar>
                <v-card-text>
                    <div>
                        <contactSelect @updatedContact="updatedContact" :accessId="company.contactId"></contactSelect>
                    </div>
                    <div style="margin-top:20px;height: 30px;">
                        <v-btn
                            text
                            @click="setContactDialog=false"
                            style="border-style: solid;float: right;"
                        >
                            Close
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-else>
                <v-toolbar
                    color="#008400"
                    dark
                >Add Contact</v-toolbar>
                <v-card-text>
                    <div>
                        <div>
                            <contactEdit @contact="processContact" :contact="contact"></contactEdit>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>             
    </div>
</template>

<script>

import CryptoJS from 'crypto-js';
import data from '@/services/data.js'
import addressEdit from '@/components/addressEdit.vue'
import contactEdit from '@/components/admin/contactEdit.vue'
import contactSelect from '@/components/admin/contactSelect.vue'
export default {
    name: 'editCompany',
    props:['id'],
    components: {
        addressEdit,
        contactEdit,
        contactSelect
    },
    // Bound properties declaration
    data() {
        return {
            headers: [
                { text: 'ID', value: 'abbreviation' },
                { text: 'Name', value: 'name' },
                { text: 'Value', value: 'value' },
            ],
    		company:{},
            companyContact:"",
            contact:{},
    		search:'',
    		selected:[],
            editCompanyDialog:false,
            setContactDialog:false,
            showPassword1:true,
            showPassword2:true,
            systems: [],
            selectedSystems: [],
            hideFooter:true,
            addressDialog:false,
            editAddress:{},
            companyDataItem:{},
            companyDataDialog:false,
            accessLevels:[
                    {level:0,name:"User"},
                    {level:1,name:"Branch Administrator"},
                    {level:2,name:"Company Administrator"},
                    {level:3,name:"System Administrator"}
            ],
            existingCompanyData:false,
            validContact:false,
        };  
    },
	methods: {
        processContact(item)
        {
            console.log("Process Contact");
            this.contact.firstName=item.firstName;
            this.contact.lastName=item.lastName;
            this.contact.mobileNumber=item.mobileNumber;
            this.contact.email=item.email;
            this.contact.access=item.access;
            this.contact.password=item.password
            ;

            var status="";
            if (this.contact.password==="")
            {
                status+=" :Invalid Password"
            }
            else
            {
                if (this.contact.password.length<8)
                {
                    status+=" :Password length invalid";
                }                
            }
            if (this.contact.email==="")
            {
                status+=" :Email address required"
            }
            if (this.contact.access===null)
            {
                status+=" :Access level required"
            }
            if (this.contact.firstName==="")
            {
                status+=" :First name required"
            }
            if (this.contact.lastName==="")
            {
                status+=" :Last name required"
            }
            if (status!=="")
            {
                this.validContact=false;
                status="("+status+")";
            }
            else
            {
                this.validContact=true;
            }
            this.companyContact=item.firstName+" "+item.lastName+" "+status;
            this.company.contactName=this.companyContact;
            this.company.contact=item.email;
            this.setContactDialog=false;
        },
        updatedContact(item)
        {
            if (item)
            {
                this.companyContact=item.firstName+" "+item.lastName;
                this.company.contactName=this.companyContact;
                this.company.contact=item.emailAddress;
                this.company.contactId=item.id;
                this.setContactDialog=false;
            }
        },
        editItem(item)
        {
            this.editCompanyDialog=true;
        },
        deleteItem(item)
        {
            console.log(item);
        },
        clearCompany()
        {
            console.log("Clear company")
        },
        async loadCompany(val)
        {
            console.log(val);
            this.company={};
            this.companyContact="Create Primary Contact";
            this.selectedSystems=[];
            if (val!=="")
            {
                var responseSystems=await data.getData("systems",this.$store.state.access.token);
                if (responseSystems.data&&responseSystems.data.data&&responseSystems.data.token)
                {
                    this.systems=responseSystems.data.data;
                    this.$store.commit("setToken",responseSystems.data.token);
                }
                else
                {
                    if (responseSystems.data.result&&responseSystems.data.message&&responseSystems.data.result==="error")
                    {
                        console.log("logout on error editCompany");
                        this.$emit("logoutOnError");
                    }
                }

                var response=await data.getItemEx("company",this.$store.state.access.token,val,3)
                if (response.data&&response.data.data&&response.data.token)
                {
                    console.log(response.data);
                    this.company=response.data.data;
                    this.companyContact=this.company.contactName;                            
                    this.validContact=true;

                    var me=this;
                    this.accessLevels.forEach(function(al){
                        if (al.level===me.company.access)
                            me.company.access=al;
                    })

                    this.$store.commit("setToken",response.data.token);
                    this.selectedSystems=[];

                    var companySystems=this.company.systems;
                    var me=this;
                    this.systems.forEach(function(item){
                        for (var i=0;i<companySystems.length;i++)
                        {
                            if (item.id===companySystems[i])
                            {
                                me.selectedSystems.push(item);
                            }
                        }
                    });

                }
                else 
                {
                    if (response.data.result&&response.data.message&&response.data.result==="error")
                    {
                        console.log("logout on error editCompany");
                        this.$emit("logoutOnError");
                    }
                }
            }
            else
            {
                this.validContact=false;
            }
        },
        updatedAddress(address)
        {
            this.company.address=address;
            console.log(address);
        },
        closeRdButton()
        {
            //Add the new item to the list
            this.companyDataDialog=false;
        },
        deleteRdButton()
        {
            var finalResult=[];
            var me=this;
            this.company.businessId.forEach(function(item){
                if (item!==me.companyDataItem)
                    finalResult.push(item);
            });
            this.company.businessId=finalResult;
            this.companyDataDialog=false;
        },
        clickData(item)
        {
            this.companyDataItem=item;
            this.companyDataDialog=true;
            this.existingCompanyData=true;
            console.log(this.companyDataItem);
        },
        addCompanyData()
        {
            this.companyDataItem={};
            this.companyDataDialog=true;
            this.existingCompanyData=false;
            console.log(this.companyDataItem);            
        },
        addRdButton()
        {
            if (this.companyDataItem!=={})
            {
                if (!this.company.businessId)
                    this.company.businessId=[];
                this.company.businessId.push(this.companyDataItem)
            }
            this.companyDataDialog=false;
        },
        clickAccess(item)
        {
            console.log(item);
        },
        async saveData()
        {
            //prepare company to be saved
            if (this.company.id&&this.company.id!=="")
            {
                var company=this.company;
                company.access=this.company.access.level;

                var systems=[];
                this.selectedSystems.forEach(function(item)
                {
                    systems.push(item.id);
                });
                company.systems=systems;

                data.writeRecord("company",this.$store.state.access.token, company)
                    .then(response=>{
                            if (response.data&&response.data.data&&response.data.token)
                            {
                                console.log(response.data);
                                this.company=response.data.data;
                                this.companyAddress=this.company.address.street+" "+this.company.address.suburb+" "+this.company.address.state+" "+this.company.address.postcode+" "+this.company.address.country;
                                this.companyContact=this.company.contactName;                            

                                var me=this;
                                this.accessLevels.forEach(function(al){
                                    if (al.level===me.company.access)
                                        me.company.access=al;
                                })

                                this.$store.commit("setToken",response.data.token);
                            }
                            else 
                            {
                                if (response.data.result&&response.data.message&&response.data.result==="error")
                                {
                                    console.log("logout on error editCompany");
                                    this.$emit("logoutOnError");
                                }
                            }
                        })
                    .catch(error=> { 
                            console.log(error)
                        });
            }
            else
            {
                var company=this.company;
                company.access=this.company.access.level;
                var systems=[];
                this.selectedSystems.forEach(function(item)
                {
                    systems.push(item.id);
                });
                company.systems=systems;

                var response1=await data.writeRecord("company",this.$store.state.access.token, company);
                if (response1.data&&response1.data.data&&response1.data.token)
                {
                    console.log("Save Company");
                    console.log(response1.data);
                    this.company=response1.data.data;
                    this.companyAddress=this.company.address.street+" "+this.company.address.suburb+" "+this.company.address.state+" "+this.company.address.postcode+" "+this.company.address.country;
                    this.companyContact=this.company.contactName;                            

                    var me=this;
                    this.accessLevels.forEach(function(al){
                        if (al.level===me.company.access)
                            me.company.access=al;
                    })

                    this.$store.commit("setToken",response1.data.token);

                    //save contact
                    var contact=this.contact;
                    this.contact.access=this.contact.access.level;
                    this.contact.companyId=this.company.id;
                    this.contact.password=CryptoJS.SHA3(this.contact.password).toString(CryptoJS.enc.Base64);
                    var response2=await data.writeRecord("access",this.$store.state.access.token, contact);
                    if (response2.data&&response2.data.data&&response2.data.token)
                    {
                        console.log("Save Contact");
                        console.log(response2.data);
                        this.contact=response2.data.data;
                        this.$store.commit("setToken",response2.data.token);

                        this.company.contactId=this.contact.id;
                        //save company with contact
                        var company=this.company;
                        company.access=this.company.access.level;

                        console.log("Save Company again before save");
                        console.log(this.company);
                        var response3= await data.writeRecord("company",this.$store.state.access.token, company);
                        if (response3.data&&response3.data.data&&response3.data.token)
                        {
                            console.log("Save Company again");
                            console.log(response3.data);
                            this.company=response3.data.data;
                            this.companyAddress=this.company.address.street+" "+this.company.address.suburb+" "+this.company.address.state+" "+this.company.address.postcode+" "+this.company.address.country;
                            this.companyContact=this.company.contactName;                            

                            var me=this;
                            this.accessLevels.forEach(function(al){
                                if (al.level===me.company.access)
                                    me.company.access=al;
                            })

                            this.$store.commit("setToken",response3.data.token);
                        }
                    }   
                }
                else 
                {
                    if (response1.data.result&&response1.data.message&&response1.data.result==="error")
                    {
                        console.log("logout on error editCompany");
                        this.$emit("logoutOnError");
                    }
                }
                console.log("New company add company record and add the contact");
            }

        },
        setContact()
        {
            console.log("Set Contact");
            this.setContactDialog=true;
        },
        requiredInfo()
        {
            var required="";
            if (!this.company.companyName)
                required+=", Company Name";
            if (!this.validContact)
                required+=", Contact Details";
            if (!this.company.address)
                required+=", Company Address";
            if (!this.company.access)
                required+=", Access Level";
            if (!this.company.businessId||this.company.businessId && this.company.businessId.length===0)
                required+=", Company Data";
            if (!this.selectedSystems>=1)
                required+=", Selected Systems";

            if (required.indexOf(",")>=0)
            {
                required=required.substring(2);
                required="("+required+")";
            }
            return required;
        }
	},
	computed: {
	},
    watch: {
        id: function(val) 
        {
            this.loadCompany(val);
        },
    },
  	created() 
	{
        this.loadCompany(this.id);
	},
};
</script>
