<template>
    <v-app>
        <v-app-bar
        app
        color="green darken-4"
        dark
        >
            <v-app-bar-nav-icon label="Menu" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>

            <div class="d-flex align-center" style="margin-left:15px;">
                <v-icon>mdi-cogs</v-icon>
            </div>
            <div style="margin-left:15px;" class="hidden-md-and-up">
                <h1 style="margin-top:5px">
                    Setup
                </h1>
            </div>
            <div style="margin-left:15px;" class="hidden-sm-and-down">
                <h1 >
                    Setup
                </h1>
            </div>
            <div style="margin-left:15px;" class="hidden-sm-and-down">
                <b>Your setup, your system, your data</b>
            </div>
        </v-app-bar>	
        <v-navigation-drawer
            v-model="sidebar"
            app
            temporary
        >
            <v-list>
            <v-list-item link>
                <v-list-item-content>
                <v-list-item-title class="text-h6">
                    Setup
                </v-list-item-title>
                <v-list-item-subtitle>{{userName}}</v-list-item-subtitle>
                <v-list-item-subtitle><b>{{company}}</b></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list
            nav
            dense
            >
            <v-list-item v-for="(item,index) in systemsData" :key="index" @click="selectedSystem(item)">
                <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>                
                <v-list-item-title>{{item.system}}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="setSelectedTab('admin');sidebar=false;">
                <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
                <v-list-item link @click="setSelectedTab('superadmin');sidebar=false;" v-if="superAdmin">
                    <v-list-item-icon>
                    <v-icon>mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>System Settings</v-list-item-title>
                </v-list-item>
            <v-list-item link @click="logout">
                <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="sidebar=false">
                <v-list-item-icon>
                <v-icon>mdi-close</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Close Menu</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-navigation-drawer>		
        <v-main>
            <div class="control-section">
                <div class="sample-container">
                    <div class="default-section">					
                        <adminView @logoutOnError="logoutError" v-if="selectedTab==='admin'"></adminView>
                        <superAdminView @logoutOnError="logoutError" v-if="selectedTab==='superadmin'"></superAdminView>
                    </div>
                </div>
            </div>
        </v-main>
        <v-footer
            class=""
            color="green darken-4"
            dark
            padless
            inset app
        >
            <v-row>
                <v-col cols=3>
                    {{gs}}
                </v-col>
                <v-col cols=6>
                    <a href="https://iotx3.com.au/x3" style="text-decoration:none !important;color:white;">
                        {{cr}}
                    </a>
                </v-col>
            </v-row>
        </v-footer>	
    </v-app>
</template>
<script>
	import superAdminView from '@/views/superAdminView.vue'
	import adminView from '@/views/adminView.vue'
    import data from '@/services/data.js'
	//import services from '../services/services.js'

	export default {
        components: {
            superAdminView,
            adminView,
        },
        name: "mainView",
            // Bound properties declaration
            data() {
                return {
                    sidebar:false,
                    ownerDataSource: [
                        { OwnerText: 'Seven Hills', id: 1, OwnerColor: '#ffaa00' },
                        { OwnerText: 'Macksville', id: 2, OwnerColor: '#f8a398' }
                    ],
                    typeDataSource: [
                        { TypeText: 'SGMI', Id: 1, TypeColor: '#ffaa00' },
                        { TypeText: 'SGM Cars', Id: 2, TypeColor: '#f8a398' },
                        { TypeText: 'Customer', Id: 3, TypeColor: '#7499e1' }
                    ],
                    eventSettings: {
                        dataSource: [
                            {
                                Id: 1,
                                Subject: 'SGM08A Service',
                                EventType: 'Confirmed',
                                StartTime: new Date(2021, 7, 10, 8, 0),
                                EndTime: new Date(2021, 7, 10, 16, 0),
                                OwnerId: 1,
                                TypeId: 1
                            },
                            {
                                Id: 2,
                                Subject: 'DD55AA',
                                EventType: 'Confirmed',
                                StartTime: new Date(2021, 7, 10, 8, 0),
                                EndTime: new Date(2021, 7, 10, 11, 30),
                                OwnerId: 2,
                                TypeId: 2
                            },
                        ]
                    },
                    systemsData:[],
                };
            },
            methods: {
                logoutError()
                {
                    console.log("Mainview logout on error");
                    this.logout();
                },
                logout()
                {
                    this.$store.commit('logout');					                
                },
                setSelectedTab(tab)
                {
                    this.$store.commit('setSelectedTab',tab);	                    				                
                },
                selectedSystem(system)
                {
                    var display="schedule";
                    if (system.display)
                        display=system.display;
                    
                    this.$store.commit('setSelectedTab',display);	
                    window.location="https://"+system.url;
                },
                loadSystems()
                {
                    this.loading=true;
                    data.getData("systems",this.$store.state.access.token)
                        .then(response=>{
                                if (response.data&&response.data.data&&response.data.token)
                                {
                                    var me=this;
                                    me.systemsData=[];
                                    var systemsData=response.data.data;
                                    if (this.$store.state.access.access>=3)
                                    {
                                        systemsData.forEach(function(item){
                                            if (!item.deleted)
                                                me.systemsData.push(item);
                                        });
                                    }
                                    else
                                    {

                                        //load each system assigned to a branch
                                        this.$store.state.currentUser.branches.forEach(async function(item){
                                            var branchResponse=await data.getItem("branches",me.$store.state.access.token,item);
                                            var result=[];
                                            branchResponse.data.data.systems.forEach(function(system){
                                                systemsData.forEach(function(systemItem){
                                                    if (systemItem.id===system)
                                                        result.push(systemItem);
                                                })
                                            });
                                            me.systemsData=result;
                                        });
                                    }
                                    this.$store.commit("setToken",response.data.token);
                                }
                                else 
                                {
                                    if (response.data.result&&response.data.message&&response.data.result==="error")
                                        {
                                            console.log("logout on error Systems");
                                        }
                                }
                                this.loading=false;
                            })
                        .catch(error=> { 
                                console.log(error)
                                this.loading=false;
                            });
                },                
            },
            computed: {
                admin() {
                    var result=false;
                    if (this.$store.state.access.access>=2)
                        result=true;

                    return result;
                },
                access() {
                    return this.$store.state.access;
                },
                authenticated() {
                    return this.$store.state.authenticated;
                },
                info()
                {
                    return this.$store.state.info;
                },
                token()
                {
                    return this.$store.state.token;
                },
                userName()
                {
                    return this.$store.state.access.firstName;
                },
                company()
                {
                    return this.$store.state.access.company;
                },
                selectedTab()
                {
                    var selectedTab="admin"
                    if (this.$store.state.selectedTab)
                        selectedTab=this.$store.state.selectedTab;
                    return selectedTab;
                },
                superAdmin()
                {
                    var result=false;
                    if (this.$store.state.access.access>=3)
                        result=true;
        
                    return result;
                }        
            },
            created() 
            {
                this.target=atob("aHR0cHM6Ly9pb3R4My5jb20uYXUveDM=");
                this.cr=atob("MjAyMqkgQnVzaW5lc3MgV29ya2Zsb3cgQXV0b21hdGlvbiBQdHkgTHRk");
                this.gs=atob("U2V0dXA=");
                this.loadSystems();
                if (!(this.selectedTab==="admin"||this.selectedTab==="superadmin"))
                    this.$store.commit('setSelectedTab','admin');	                    				                
            },
    }
</script>

<style>
</style>