<template>
	<div>
		<div style="background-color:#00A400;color:white; margin-top:15px;padding-left:15px;">
			Systems
		</div>
        <v-row style="margin-top:0px; margin-left:5px;">
            <div style="margin-left:5px; margin-bottom:10px;margin-top:5px;">
                <v-btn @click="editSystemsDialog=true;" style="border-style: solid;border-color: grey;">Edit</v-btn>

            </div>
            <div v-if="systemsData.length===0&&!loading" style="margin-left:10px; margin-top:10px;">
                No systems setup yet
            </div>
            <div v-else style="margin-left:10px; margin-top:10px;">
                Systems: {{systemCount()}} 
            </div>
        </v-row>
        <v-dialog fullscreen hide-overlay v-model="editSystemsDialog">
            <v-toolbar
            dark
            style="background-color: #00A400;"
            >
                <v-toolbar-title ><v-icon style="margin-right:5px;margin-top:-3px;">mdi-web</v-icon>Systems</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                            icon
                            dark
                            @click="editSystemsDialog = false; cancelChanges();"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
            </v-toolbar>
            <v-card class="px-4" @keyup.enter.native="validate" style="height:600px;">
                <v-card-text>
                    <div>
                        <v-row>
                            <v-btn style="margin-top:12px;margin-left:10px;border-style: solid;border-color: white;" @click="action='new'">
                                Add System
                            </v-btn>
                            <v-checkbox
                                v-model="showDeleted"
                                enabled
                                label="Show Deleted"
                                style="margin-left:30px;"                                
                            ></v-checkbox>
                        </v-row>
                        <v-data-table
                            style="margin-left:0px;margin-right:15px"
                            v-model="selectSystems"
                            :headers="headers"
                            :items="filteredData"
                            single-select
                            class="elevation-1"
                        >
                            <template v-slot:item.system="{ item }">
                                <div v-if="!item.deleted">{{item.system}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.system}}</strike>
                            </template>		
                            <template v-slot:item.systemId="{ item }">
                                <div v-if="!item.deleted">{{item.systemId}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.systemId}}</strike>
                            </template>		
                            <template v-slot:item.url="{ item }">
                                <div v-if="!item.deleted">{{item.url}}</div>
                                <strike v-else style="color:red;text-decoration-style: ;">{{item.url}}</strike>
                            </template>		
                            <template v-slot:item.icon="{ item }">
                                <v-icon v-if="!item.deleted" style="margin-right:10px;">{{item.icon}}</v-icon>
                                <v-icon v-else style="color:red;margin-right:10px;">{{item.icon}}</v-icon>
                                {{item.icon}}
                            </template>		
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    v-if="!item.deleted"
                                    small
                                    @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                    v-else
                                    small
                                    @click="deleteItem(item)"
                                    style="color:red"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>		
                        </v-data-table>	

                        <div v-if="action==='edit'||action==='new'">
                            <v-row>
                                <v-col cols="3">
                                    <div style="background-color:navy;color:white; padding-left:15px;margin-top:20px;">
                                        System Name
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                        v-model="system.system"
                                        label="System Name"
                                        single-line
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-45px;">
                                <v-col cols="3">
                                    <div style="background-color:navy;color:white; padding-left:15px;margin-top:20px;">
                                        System ID
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                        v-model="system.systemId"
                                        label="System ID"
                                        single-line
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>        
                            <v-row style="margin-top:-45px;">
                                <v-col cols="3">
                                    <div style="background-color:navy;color:white; padding-left:15px;margin-top:20px;">
                                        URL
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                        v-model="system.url"
                                        label="URL"
                                        single-line
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>                      
                            <v-row style="margin-top:-45px;">
                                <v-col cols="3">
                                    <div style="background-color:navy;color:white; padding-left:15px;margin-top:20px;">
                                        Default Display
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field
                                        v-model="system.display"
                                        label="Default display"
                                        single-line
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>    
                            <v-row style="margin-top:-45px;">
                                <v-col cols="3">
                                    <div style="background-color:navy;color:white; padding-left:15px;margin-top:20px;">
                                        Icon
                                    </div>
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field
                                        v-model="system.icon"
                                        label="Icon"
                                        single-line
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-icon style="margin-top:20px;">{{system.icon}}</v-icon>
                                </v-col>
                            </v-row>                      
                            <v-row style="margin-top:-45px;" v-if="showDeleted">
                                <v-col cols="3">
                                    <div style="background-color:navy;color:white; padding-left:15px;margin-top:20px;">
                                        Deleted
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <v-checkbox
                                        v-model="system.deleted"
                                        enabled
                                        style="margin-right:10px;"
                                    ></v-checkbox>
                                </v-col>                            
                            </v-row>                      
                            <v-row style="margin-top:-15px;">
                                <v-col cols="3">
                                </v-col>
                                <v-btn
                                    dark
                                    @click="saveData"
                                    style="margin-left:10px;margin-top:5px;float:right;background-color:LimeGreen;width:250px;"
                                    v-if="system.system&&system.systemId&&system.url&&system.system!==''&&system.systemId!==''&&system.url!==''"
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-content-save
                                    </v-icon> Save
                                </v-btn>
                                <v-btn
                                    dark
                                    style="margin-left:10px;margin-top:5px;float:right;background-color:Tomato;width:250px;"
                                    @click="cancelChanges"
                                >
                                    <v-icon dark style="margin-right:10px;">
                                        mdi-close
                                    </v-icon> Cancel Changes
                                </v-btn>                            
                            </v-row>                                           
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
	</div>
</template>

<script>
import data from '@/services/data.js'
export default {
    name: 'systems',
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            editSystemsDialog:false,
            companyId:"",
            systemsData:[],
            selectSystems:[],
            system:{},
            headers: [
                { text: 'System Name', value: 'system' },
                { text: 'System ID', value: 'systemId' },
                { text: 'URL', value: 'url' },
                { text: 'Icon', value: 'icon' },
    			{ text: 'Actions', value: 'actions', sortable: false }
            ],
            action:'',
            showDeleted:false,
            loading:false,
        };
    },
	methods: {
        cancelChanges()
        {
            this.action="";
            this.system={};
        },
        listSystems()
        {
            var systems="";

            this.systemsData.forEach(function(item)
            {
                if (!item.deleted)
                    systems+=", "+item.systemId;
            });
            if (systems.indexOf(",")>=0)
                systems=systems.substring(2);
            
            return systems;
        },
        editItem(item)
        {
            this.system={};
            this.system.system=item.system;
            this.system.systemId=item.systemId;
            this.system.url=item.url;
            this.system.display=item.display;
            this.system.id=item.id;
            this.system.icon=item.icon;
            this.system.deleted=item.deleted;

            this.action="edit";
            console.log(item);
        },
        deleteItem(item)
        {
            this.system={};
            this.system.system=item.system;
            this.system.systemId=item.systemId;
            this.system.url=item.url;
            this.system.display=item.display;
            this.system.id=item.id;
            this.system.icon=item.icon;
            this.system.deleted=item.deleted;
            this.system.deleted=!this.system.deleted

            this.saveData();
            console.log(item);
        },
        saveData()
        {
            var currentAction=this.action;
            this.action="";
            console.log(this.system);
            data.writeRecord("systems",this.$store.state.access.token, this.system)
                    .then(response=>{
                            if (response.data&&response.data.data&&response.data.token)
                            {
                                console.log(response.data);
                                this.$store.commit("setToken",response.data.token);
                            }
                            else 
                            {
                                if (response.data.result&&response.data.message&&response.data.result==="error")
                                {
                                    this.action=currentAction;
                                    this.saveError="Error saving system"
                                    console.log("logout on error editCompany");
                                }
                            }

                            this.loadSystems();
                        })
                    .catch(error=> { 
                            this.action=currentAction;
                            this.saveError="Error saving system"
                            console.log(error)
                        });            
        },
        loadSystems()
        {
            this.loading=true;
            data.getData("systems",this.$store.state.access.token)
                .then(response=>{
                        if (response.data&&response.data.data&&response.data.token)
                        {
                            this.systemsData=response.data.data;
                            console.log(this.systemsData);
                            this.$store.commit("setToken",response.data.token);
                            console.log("Systems data set");
                        }
                        else 
                        {
                            if (response.data.result&&response.data.message&&response.data.result==="error")
                                {
                                    console.log("logout on error Systems");
                                    //this.$emit("logoutOnError");
                                }
                        }
                        this.loading=false;
                    })
                .catch(error=> { 
                        console.log(error)
                        this.loading=false;
                    });
        },
        systemCount: function()
        {
            var systemCnt=0;
            this.systemsData.forEach(function(item)
            {
                if (!item.deleted) systemCnt++;
            });
            
            return systemCnt;
        },

        /*
        logoutError()
        {
            this.$emit("logoutOnError");
        },
        editItem(item)
        {
            console.log("Set item "+item.id)
            this.companyId="";
            this.editCompanyDialog=true;
            this.companyId=item.id;
        },
        addCompany()
        {
            this.companyId="";
            this.editCompanyDialog=true;
        },
        deleteItem(item)
        {
            console.log(item);
        },
        getAccessLabel(accessLevel)
        {
            var result="";
            if (accessLevel.access>=0)
            {
                this.accessLevels.forEach(function(item)
                {
                    if (item.level===accessLevel.access)
                        result=item.name;

                });
            }
            return(result);
        },*/
	},
	computed: {
        filteredData: function()
        {
            var data=this.systemsData;

            if (!this.showDeleted)
            {
                data=[];
                this.systemsData.forEach(function(item){
                    if (!item.deleted)
                        data.push(item);
                });
            }

            return data;
        }
	},
  	created() 
	{
        this.loadSystems();
	},
};
</script>
