<template>
	<div style="margin-top:5px;">
        <v-row @click="addressDialog=true" style="margin-left:1px;">
            <v-btn
                dark
                style="margin-top:5px;  width:250px;background-color: dodgerblue;"
            >
                <v-icon dark style="margin-right:10px;">
                    mdi-mailbox
                </v-icon> Edit Address
            </v-btn>
            <b style="font-size: medium; margin-left:10px; margin-top:12px;">{{companyAddress}}</b>
        </v-row>
        <v-dialog
            v-model="addressDialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-card>
                <v-toolbar
                color="#008400"
                dark
                >Edit Address</v-toolbar>
                <v-card-text>
                    <v-row style="margin-top:5px;">
                        <v-col cols=12>
                            <v-text-field
                                v-model="street"
                                :counter="100"
                                label="Street Address"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>	
                    <v-row style="margin-top:-25px;">
                        <v-col cols=4>
                            <v-text-field
                                v-model="postcode"
                                :counter="4"
                                label="Postcode"
                                required
                                @keyup="postcodeChange"
                                autocomplete="chrome-off"
                            ></v-text-field>					
                        </v-col>
                        <v-col v-if="postcode && postcode.length===4" cols=8>
                            <v-combobox
                                v-model="suburb"
                                :items="suburbs"
                                item-text="suburb"
                                hide-selected
                                label="Select Suburb"
                                autocomplete="chrome-off"
                                @change="suburbSelected"
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>	
                    <v-row style="margin-top:-25px;">
                        <v-col cols=4>
                            <v-combobox
                                    v-model="state"
                                    :items="states"
                                    hide-selected
                                    label="Select state"
                                    autocomplete="chrome-off"
                            >
                            </v-combobox>
                        </v-col>
                        <v-col cols=8>
                            <v-combobox
                                    v-model="country"
                                    :items="countries"
                                    item-text="name"
                                    hide-selected
                                    label="Select country"
                                    autocomplete="chrome-off"
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>	
                    <div style="margin-top:10px;height: 30px;">
                        <v-btn
                            text
                            @click="closeButton"
                            style="border-style: solid;float: right;"
                            hidden
                        >
                            Close
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>    
    </div>
</template>

<script>
import postcodes from '@/services/postcodes.js'
export default {
    name: 'editAddress',
    props:['inAddress'],
    components: {
    },
    // Bound properties declaration
    data() {
        return {
            suburbs:[],
            countries:[ 
                {name: 'Afghanistan', code: 'AF'}, 
                {name: 'Åland Islands', code: 'AX'}, 
                {name: 'Albania', code: 'AL'}, 
                {name: 'Algeria', code: 'DZ'}, 
                {name: 'American Samoa', code: 'AS'}, 
                {name: 'AndorrA', code: 'AD'}, 
                {name: 'Angola', code: 'AO'}, 
                {name: 'Anguilla', code: 'AI'}, 
                {name: 'Antarctica', code: 'AQ'}, 
                {name: 'Antigua and Barbuda', code: 'AG'}, 
                {name: 'Argentina', code: 'AR'}, 
                {name: 'Armenia', code: 'AM'}, 
                {name: 'Aruba', code: 'AW'}, 
                {name: 'Australia', code: 'AU'}, 
                {name: 'Austria', code: 'AT'}, 
                {name: 'Azerbaijan', code: 'AZ'}, 
                {name: 'Bahamas', code: 'BS'}, 
                {name: 'Bahrain', code: 'BH'}, 
                {name: 'Bangladesh', code: 'BD'}, 
                {name: 'Barbados', code: 'BB'}, 
                {name: 'Belarus', code: 'BY'}, 
                {name: 'Belgium', code: 'BE'}, 
                {name: 'Belize', code: 'BZ'}, 
                {name: 'Benin', code: 'BJ'}, 
                {name: 'Bermuda', code: 'BM'}, 
                {name: 'Bhutan', code: 'BT'}, 
                {name: 'Bolivia', code: 'BO'}, 
                {name: 'Bosnia and Herzegovina', code: 'BA'}, 
                {name: 'Botswana', code: 'BW'}, 
                {name: 'Bouvet Island', code: 'BV'}, 
                {name: 'Brazil', code: 'BR'}, 
                {name: 'British Indian Ocean Territory', code: 'IO'}, 
                {name: 'Brunei Darussalam', code: 'BN'}, 
                {name: 'Bulgaria', code: 'BG'}, 
                {name: 'Burkina Faso', code: 'BF'}, 
                {name: 'Burundi', code: 'BI'}, 
                {name: 'Cambodia', code: 'KH'}, 
                {name: 'Cameroon', code: 'CM'}, 
                {name: 'Canada', code: 'CA'}, 
                {name: 'Cape Verde', code: 'CV'}, 
                {name: 'Cayman Islands', code: 'KY'}, 
                {name: 'Central African Republic', code: 'CF'}, 
                {name: 'Chad', code: 'TD'}, 
                {name: 'Chile', code: 'CL'}, 
                {name: 'China', code: 'CN'}, 
                {name: 'Christmas Island', code: 'CX'}, 
                {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
                {name: 'Colombia', code: 'CO'}, 
                {name: 'Comoros', code: 'KM'}, 
                {name: 'Congo', code: 'CG'}, 
                {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
                {name: 'Cook Islands', code: 'CK'}, 
                {name: 'Costa Rica', code: 'CR'}, 
                {name: 'Cote D\'Ivoire', code: 'CI'}, 
                {name: 'Croatia', code: 'HR'}, 
                {name: 'Cuba', code: 'CU'}, 
                {name: 'Cyprus', code: 'CY'}, 
                {name: 'Czech Republic', code: 'CZ'}, 
                {name: 'Denmark', code: 'DK'}, 
                {name: 'Djibouti', code: 'DJ'}, 
                {name: 'Dominica', code: 'DM'}, 
                {name: 'Dominican Republic', code: 'DO'}, 
                {name: 'Ecuador', code: 'EC'}, 
                {name: 'Egypt', code: 'EG'}, 
                {name: 'El Salvador', code: 'SV'}, 
                {name: 'Equatorial Guinea', code: 'GQ'}, 
                {name: 'Eritrea', code: 'ER'}, 
                {name: 'Estonia', code: 'EE'}, 
                {name: 'Ethiopia', code: 'ET'}, 
                {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
                {name: 'Faroe Islands', code: 'FO'}, 
                {name: 'Fiji', code: 'FJ'}, 
                {name: 'Finland', code: 'FI'}, 
                {name: 'France', code: 'FR'}, 
                {name: 'French Guiana', code: 'GF'}, 
                {name: 'French Polynesia', code: 'PF'}, 
                {name: 'French Southern Territories', code: 'TF'}, 
                {name: 'Gabon', code: 'GA'}, 
                {name: 'Gambia', code: 'GM'}, 
                {name: 'Georgia', code: 'GE'}, 
                {name: 'Germany', code: 'DE'}, 
                {name: 'Ghana', code: 'GH'}, 
                {name: 'Gibraltar', code: 'GI'}, 
                {name: 'Greece', code: 'GR'}, 
                {name: 'Greenland', code: 'GL'}, 
                {name: 'Grenada', code: 'GD'}, 
                {name: 'Guadeloupe', code: 'GP'}, 
                {name: 'Guam', code: 'GU'}, 
                {name: 'Guatemala', code: 'GT'}, 
                {name: 'Guernsey', code: 'GG'}, 
                {name: 'Guinea', code: 'GN'}, 
                {name: 'Guinea-Bissau', code: 'GW'}, 
                {name: 'Guyana', code: 'GY'}, 
                {name: 'Haiti', code: 'HT'}, 
                {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
                {name: 'Holy See (Vatican City State)', code: 'VA'}, 
                {name: 'Honduras', code: 'HN'}, 
                {name: 'Hong Kong', code: 'HK'}, 
                {name: 'Hungary', code: 'HU'}, 
                {name: 'Iceland', code: 'IS'}, 
                {name: 'India', code: 'IN'}, 
                {name: 'Indonesia', code: 'ID'}, 
                {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
                {name: 'Iraq', code: 'IQ'}, 
                {name: 'Ireland', code: 'IE'}, 
                {name: 'Isle of Man', code: 'IM'}, 
                {name: 'Israel', code: 'IL'}, 
                {name: 'Italy', code: 'IT'}, 
                {name: 'Jamaica', code: 'JM'}, 
                {name: 'Japan', code: 'JP'}, 
                {name: 'Jersey', code: 'JE'}, 
                {name: 'Jordan', code: 'JO'}, 
                {name: 'Kazakhstan', code: 'KZ'}, 
                {name: 'Kenya', code: 'KE'}, 
                {name: 'Kiribati', code: 'KI'}, 
                {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
                {name: 'Korea, Republic of', code: 'KR'}, 
                {name: 'Kuwait', code: 'KW'}, 
                {name: 'Kyrgyzstan', code: 'KG'}, 
                {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
                {name: 'Latvia', code: 'LV'}, 
                {name: 'Lebanon', code: 'LB'}, 
                {name: 'Lesotho', code: 'LS'}, 
                {name: 'Liberia', code: 'LR'}, 
                {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
                {name: 'Liechtenstein', code: 'LI'}, 
                {name: 'Lithuania', code: 'LT'}, 
                {name: 'Luxembourg', code: 'LU'}, 
                {name: 'Macao', code: 'MO'}, 
                {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
                {name: 'Madagascar', code: 'MG'}, 
                {name: 'Malawi', code: 'MW'}, 
                {name: 'Malaysia', code: 'MY'}, 
                {name: 'Maldives', code: 'MV'}, 
                {name: 'Mali', code: 'ML'}, 
                {name: 'Malta', code: 'MT'}, 
                {name: 'Marshall Islands', code: 'MH'}, 
                {name: 'Martinique', code: 'MQ'}, 
                {name: 'Mauritania', code: 'MR'}, 
                {name: 'Mauritius', code: 'MU'}, 
                {name: 'Mayotte', code: 'YT'}, 
                {name: 'Mexico', code: 'MX'}, 
                {name: 'Micronesia, Federated States of', code: 'FM'}, 
                {name: 'Moldova, Republic of', code: 'MD'}, 
                {name: 'Monaco', code: 'MC'}, 
                {name: 'Mongolia', code: 'MN'}, 
                {name: 'Montserrat', code: 'MS'}, 
                {name: 'Morocco', code: 'MA'}, 
                {name: 'Mozambique', code: 'MZ'}, 
                {name: 'Myanmar', code: 'MM'}, 
                {name: 'Namibia', code: 'NA'}, 
                {name: 'Nauru', code: 'NR'}, 
                {name: 'Nepal', code: 'NP'}, 
                {name: 'Netherlands', code: 'NL'}, 
                {name: 'Netherlands Antilles', code: 'AN'}, 
                {name: 'New Caledonia', code: 'NC'}, 
                {name: 'New Zealand', code: 'NZ'}, 
                {name: 'Nicaragua', code: 'NI'}, 
                {name: 'Niger', code: 'NE'}, 
                {name: 'Nigeria', code: 'NG'}, 
                {name: 'Niue', code: 'NU'}, 
                {name: 'Norfolk Island', code: 'NF'}, 
                {name: 'Northern Mariana Islands', code: 'MP'}, 
                {name: 'Norway', code: 'NO'}, 
                {name: 'Oman', code: 'OM'}, 
                {name: 'Pakistan', code: 'PK'}, 
                {name: 'Palau', code: 'PW'}, 
                {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
                {name: 'Panama', code: 'PA'}, 
                {name: 'Papua New Guinea', code: 'PG'}, 
                {name: 'Paraguay', code: 'PY'}, 
                {name: 'Peru', code: 'PE'}, 
                {name: 'Philippines', code: 'PH'}, 
                {name: 'Pitcairn', code: 'PN'}, 
                {name: 'Poland', code: 'PL'}, 
                {name: 'Portugal', code: 'PT'}, 
                {name: 'Puerto Rico', code: 'PR'}, 
                {name: 'Qatar', code: 'QA'}, 
                {name: 'Reunion', code: 'RE'}, 
                {name: 'Romania', code: 'RO'}, 
                {name: 'Russian Federation', code: 'RU'}, 
                {name: 'RWANDA', code: 'RW'}, 
                {name: 'Saint Helena', code: 'SH'}, 
                {name: 'Saint Kitts and Nevis', code: 'KN'}, 
                {name: 'Saint Lucia', code: 'LC'}, 
                {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
                {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
                {name: 'Samoa', code: 'WS'}, 
                {name: 'San Marino', code: 'SM'}, 
                {name: 'Sao Tome and Principe', code: 'ST'}, 
                {name: 'Saudi Arabia', code: 'SA'}, 
                {name: 'Senegal', code: 'SN'}, 
                {name: 'Serbia and Montenegro', code: 'CS'}, 
                {name: 'Seychelles', code: 'SC'}, 
                {name: 'Sierra Leone', code: 'SL'}, 
                {name: 'Singapore', code: 'SG'}, 
                {name: 'Slovakia', code: 'SK'}, 
                {name: 'Slovenia', code: 'SI'}, 
                {name: 'Solomon Islands', code: 'SB'}, 
                {name: 'Somalia', code: 'SO'}, 
                {name: 'South Africa', code: 'ZA'}, 
                {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
                {name: 'Spain', code: 'ES'}, 
                {name: 'Sri Lanka', code: 'LK'}, 
                {name: 'Sudan', code: 'SD'}, 
                {name: 'Suriname', code: 'SR'}, 
                {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
                {name: 'Swaziland', code: 'SZ'}, 
                {name: 'Sweden', code: 'SE'}, 
                {name: 'Switzerland', code: 'CH'}, 
                {name: 'Syrian Arab Republic', code: 'SY'}, 
                {name: 'Taiwan, Province of China', code: 'TW'}, 
                {name: 'Tajikistan', code: 'TJ'}, 
                {name: 'Tanzania, United Republic of', code: 'TZ'}, 
                {name: 'Thailand', code: 'TH'}, 
                {name: 'Timor-Leste', code: 'TL'}, 
                {name: 'Togo', code: 'TG'}, 
                {name: 'Tokelau', code: 'TK'}, 
                {name: 'Tonga', code: 'TO'}, 
                {name: 'Trinidad and Tobago', code: 'TT'}, 
                {name: 'Tunisia', code: 'TN'}, 
                {name: 'Turkey', code: 'TR'}, 
                {name: 'Turkmenistan', code: 'TM'}, 
                {name: 'Turks and Caicos Islands', code: 'TC'}, 
                {name: 'Tuvalu', code: 'TV'}, 
                {name: 'Uganda', code: 'UG'}, 
                {name: 'Ukraine', code: 'UA'}, 
                {name: 'United Arab Emirates', code: 'AE'}, 
                {name: 'United Kingdom', code: 'GB'}, 
                {name: 'United States', code: 'US'}, 
                {name: 'United States Minor Outlying Islands', code: 'UM'}, 
                {name: 'Uruguay', code: 'UY'}, 
                {name: 'Uzbekistan', code: 'UZ'}, 
                {name: 'Vanuatu', code: 'VU'}, 
                {name: 'Venezuela', code: 'VE'}, 
                {name: 'Viet Nam', code: 'VN'}, 
                {name: 'Virgin Islands, British', code: 'VG'}, 
                {name: 'Virgin Islands, U.S.', code: 'VI'}, 
                {name: 'Wallis and Futuna', code: 'WF'}, 
                {name: 'Western Sahara', code: 'EH'}, 
                {name: 'Yemen', code: 'YE'}, 
                {name: 'Zambia', code: 'ZM'}, 
                {name: 'Zimbabwe', code: 'ZW'} 
            ],
            states:["NSW","VIC","QLD","WA","SA","TAS","ACT","NT"],
            state:"",
            street:"",
            postcode:"",
            suburb:"",
            state:"",
            country:"Australia",
            addressDialog:false,
            companyAddress:"",
        };
    },
	methods: {
		postcodeChange()
		{
			if (this.postcode.length==4)
			{
                console.log("Get suburbs");
				postcodes.getSuburbs(this.postcode)
                    .then(response=>{
                        console.log(response);
                        if (response.data&&response.data.suburbs)
                            this.suburbs=response.data.suburbs;
                        else
                            this.suburbs=[];
                        if (this.suburbs.length>0)
                            this.state=this.suburbs[0].state;
                        console.log(this.state);
                    })
                    .catch(error=> { console.log(error)})
			}
		},	
        suburbSelected(item)
        {
            this.state=item.state;
        },
        loadAddress(val)
        {
            if (val&&val.street&&val.street!=="")
            {
                this.street=val.street;
                this.suburb=val.suburb;
                this.postcode=val.postcode;
                this.state=val.state;
                this.country=val.country;
                this.companyAddress="";
                if (val.street&&val.street+""!=="") this.companyAddress=val.street + " ";
                if (val.suburb&&val.suburb+""!=="") this.companyAddress+=val.suburb + " ";
                if (val.state&&val.state+""!=="") this.companyAddress+=val.state + " ";
                if (val.postcode&&val.postcode+""!=="") this.companyAddress+=val.postcode + " ";
                if (val.country&&val.country+""!=="") this.companyAddress+=val.country;
            }
            else
            {
                this.street="";
                this.suburb="";
                this.postcode="";
                this.state="";
                this.country="Australia";
                this.companyAddress="Set Address";
            }
        },
        closeButton()
        {
            var address={};
            if (this.street) address.street=this.street; else address.street="";
            if (this.suburb&&this.suburb.suburb) 
                address.suburb=this.suburb.suburb; 
            else 
            {
                if (this.suburb) address.suburb=this.suburb; else address.suburb="";
                
            }
            if (this.postcode) address.postcode=this.postcode; else address.postcode="";
            if (this.state) address.state=this.state; else address.state="";
            if (this.country.name) 
                address.country=this.country.name; 
            else 
                address.country=this.country;

            this.companyAddress="";
            if (this.street&&this.street+""!=="") this.companyAddress=this.street + " ";
            if (this.suburb&&this.suburb.suburb) this.companyAddress+=this.suburb.suburb + " "; else this.companyAddress+=this.suburb + " ";
            if (this.state&&this.state+""!=="") this.companyAddress+=this.state + " ";
            if (this.postcode&&this.postcode+""!=="") this.companyAddress+=this.postcode + " ";
            if (this.country&&this.country+""!=="") this.companyAddress+=this.country;
            console.log(this.suburb);

            this.addressDialog=false;
            this.$emit('updatedAddress',address);
        }

	},
	computed: {
	},
    watch: {
        inAddress: function(val) 
        {
            this.loadAddress(val);
        }

    },
  	created() 
	{
        if (this.inAddress&&this.inAddress.street)
            this.loadAddress(this.inAddress);
	},
};
</script>