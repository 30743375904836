<template>
  <v-app id="Home"> 
        <v-btn @click="login=true;tab=0;" style="margin-left:15px;width:350px;margin-top:10px;color:grey;background-color: transparent;border-width:thin;border-color:grey;border-style:solid; margin-bottom:8px;">Login</v-btn>

        <v-dialog v-model="login" persistent max-width="600px" min-width="360px">
            <div>
                <v-tabs v-model="tab" show-arrows background-color="light-blue" icons-and-text dark grow>
                    <v-tabs-slider color="purple darken-4"></v-tabs-slider>
                    <v-tab v-for="(i,index) in tabs" :key="index">
                        <v-icon large style="color:white">{{ i.icon }}</v-icon>
                        <div class="caption py-1" style="color:white">{{ i.name }}</div>
                    </v-tab>
                    <v-tab-item>
                        <v-card class="px-4" @keyup.enter.native="validate">
                            <v-card-text>
                                <v-form ref="loginForm" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="6" style="margin-top:15px;">
                                            Display Theme
                                        </v-col>
                                        <v-col class="d-flex" cols="3" sm="3" xsm="3">
                                            <v-btn x-large block color="white" @click="light" style="color:black;"> Light </v-btn>
                                        </v-col>
                                        <v-col class="d-flex" cols="3" sm="3" xsm="3">
                                            <v-btn x-large block color="black" @click="dark" style="color:white;"> Dark </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginEmail" label="User Name" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" style="margin-top:-30px;">
                                            <v-text-field v-model="loginPassword" :append-icon="show1?'eye':'eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" style="margin-bottom:5px;color:white;background-color:red;" v-if="invalidLogin" >
                                            Invalid login attempt
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6"></v-col>
                                        <v-col class="d-flex" cols="3" sm="3" xsm="3" align-end>
                                            <v-btn x-large block color="error" @click="login=false"> Cancel </v-btn>
                                        </v-col>
                                        <v-col class="d-flex" cols="3" sm="3" xsm="3" align-end>
                                            <v-btn x-large block :disabled="false&&!valid" color="success" @click="validate"> Login </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </div>
        </v-dialog>        
  </v-app>
</template>

<script>
// :style="{ backgroundImage: `url(${require('@/assets/worksh0p.png')})`}"
import authorisation from '../services/authorisation.js'
import data from '@/services/data.js'
import CryptoJS from 'crypto-js';

export default {
    name: "frontView",
    components: {
    },
    data() {
        return {
            invalidLogin:false,
            login: false,
            valid:false,
            tab: 0,
            tabR: 0,
            tabs: [
                {name:"Login", icon:"mdi-account"},
            ],
            tabsR: [
                {name:"Register", icon:"mdi-account-outline"},
            ],
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            verify: "",
            loginPassword: "",
            loginEmail: "",
            loginEmailRules: [
            v => !!v || "Required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            emailRules: [
            v => !!v || "Required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],

            show1: false,
            rules: {
            required: value => !!value || "Required.",
            min: v => (v && v.length >= 8) || "Min 8 characters"
            }
        };
    },
    methods: {
        validate() {
            this.invalidLogin=false;
            if (this.loginEmail!=="" && this.loginEmail.indexOf("@")>0)
            {
                var password=CryptoJS.SHA3(this.loginPassword).toString(CryptoJS.enc.Base64);
    			authorisation
                        .login(this.loginEmail, password, "worksh0p")
                        .then(async response=>{
                                if (response.data&&response.data.data)
                                {
                                    this.$store.commit("setAccessLevel",response.data.data);
                                    this.$store.commit('setSelectedTab',"admin");					                
                                    localStorage.setItem("SystemToken", response.data.data.token);
                                    this.$store.commit("login");
                                    var response2=await data.getItem("access",response.data.data.token,response.data.data.id)
                                    console.log(response2);
                                    if (response2.data&&response2.data.data)
                                    {
                                        this.$store.commit('setUser',response2.data.data);					                
                                    }
                                }
                                else
                                {
                                    this.invalidLogin=true;
                                }
                            })
                        .catch(error=> { 
                                this.invalidLogin=true;
                            });
            }
            else
            {
                this.invalidLogin=true;
            }
        },
        reset() {
        },
        resetValidation() {
        },
        light()
        {
            this.$store.commit('setDarkmode',false);
            this.$vuetify.theme.dark=false;
        },
        dark()
        {
            this.$store.commit('setDarkmode',true);
            this.$vuetify.theme.dark=true;
        }
    },
    computed: {
        passwordMatch() {
        return () => this.password === this.verify || "Password must match";
        }
    },
};
</script>

<style>
</style>