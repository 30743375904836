<template>
	<div>
        <div style="background-color:#008400;color:white; margin-top:0px;padding-left:10px;">
            Settings
        </div>

        <div style="background-color:#00A400;color:white; margin-top:5px;padding-left:10px;">
            Display Theme
        </div>
        <v-row style="margin-left:5px; margin-top:-5px;">
            <v-col class="d-flex" cols="3" sm="3" xsm="3">
                <v-btn block color="white" @click="light" style="color:black; border-color:grey !important; border-style: solid;"> Light </v-btn>
            </v-col>
            <v-col class="d-flex" cols="3" sm="3" xsm="3">
                <v-btn block color="black" @click="dark" style="color:white; border-color:grey !important; border-style: solid;"> Dark </v-btn>
            </v-col>
        </v-row>
        <div style="background-color:#00A400;color:white; margin-top:10px;padding-left:10px;">
            Change User Settings
        </div>
        <userSettings></userSettings>

        <div style="background-color:#00A400;color:white; margin-top:10px;padding-left:10px;" v-if="companyAdmin">
            Company
        </div>
        <v-row style="margin-top:0px; margin-left:5px;" v-if="companyAdmin">
            <div style="margin-left:5px; margin-bottom:10px;margin-top:5px;">
                <v-btn @click="editCompanyDialog=true;" style="border-style: solid;border-color: grey;">Edit</v-btn>
            </div>
            <div style="margin-left:10px; margin-top:10px;">
                    {{this.$store.state.access.company}}
            </div>
        </v-row>

        <branches @logoutOnError="errorLogout" v-if="companyAdmin"></branches>
        <users @logoutOnError="errorLogout" v-if="branchAdmin||companyAdmin"></users>

        <v-dialog fullscreen hide-overlay v-model="editCompanyDialog">
                    <v-card class="px-4" @keyup.enter.native="validate">
                        <v-toolbar
                        dark
                        style="background-color: #008400;"
                        >
                        <v-toolbar-title v-if="companyId===''"><v-icon style="margin-left:10px;margin-right:5px;margin-top:-5px;">mdi-factory</v-icon>Add Company</v-toolbar-title>
                        <v-toolbar-title v-else><v-icon style="margin-left:10px;margin-right:5px;margin-top:-5px;">mdi-factory</v-icon>Edit Company</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="editCompanyDialog = false;"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <editCompany @closeDialog="editCompanyDialog = false;" @logoutOnError="errorLogout" :id="companyId"></editCompany>
                        </v-card-text>
                    </v-card>
        </v-dialog>

    </div>
</template>

<script>
import userSettings from '@/components/admin/userSettings.vue'
import editCompany from '@/components/admin/editCompany.vue'
import branches from '@/components/admin/branches.vue'
import users from '@/components/admin/users.vue'

export default {
    name: 'adminView',
    components: {
        userSettings,
        editCompany,
        branches,
        users
    },
    // Bound properties declaration
    data() {
        return {
            editCompanyDialog:false,
            companyId:""
        };
    },
	methods: {
        errorLogout()
        {
            console.log("Logout on error superadminview");
            this.$emit("logoutOnError");
        },
        light()
        {
            this.$store.commit('setDarkmode',false);
            this.$vuetify.theme.dark=false;
            console.log("Light mode")
        },
        dark()
        {
            this.$store.commit('setDarkmode',true);
            this.$vuetify.theme.dark=true;
            console.log("Dark mode")
        },
	},
	computed: {
        companyAdmin() {
                    var result=false;
                    if (this.$store.state.access.access>=2)
                        result=true;

                    return result;
                },
        branchAdmin() {
            var result=false;
            if (this.$store.state.access.access>=1)
                result=true;

            return result;
        },	},
  	created() 
	{
        this.companyId=this.$store.state.access.companyId;
	},
};
</script>
