<template>
	<div>
		<div style="background-color:#00A400;color:white; margin-top:5px;padding-left:15px;">
			Companies
		</div>
		<v-row style="margin-left:5px;margin-right:15px">
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details
				style="margin-bottom: 5px;"
			></v-text-field>
            <v-btn style="margin-top:12px;margin-left:15px;border-style: solid;border-color: white;" @click="addCompany">
                Add Company
            </v-btn>
		</v-row>
		<v-data-table
            style="margin-left:5px;margin-right:15px"
			v-model="selected"
			:headers="headers"
			:items="companies"
			item-key="_id"
			single-select
			class="elevation-1"
			:search="search"
        >
            <template v-slot:item.companyName="{ item }">
                <td v-if="item.deleted===true" style="color:red;">{{ item.companyName }}</td>
                <td v-else>{{ item.companyName }}</td>
            </template>		
            <template v-slot:item.access="{ item }">
                <td>{{ getAccessLabel(item) }}</td>
            </template>		
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-if="!item.deleted"
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>		
		</v-data-table>	
        <v-dialog fullscreen hide-overlay v-model="editCompanyDialog">
                    <v-card class="px-4" @keyup.enter.native="validate">
                        <v-toolbar
                        dark
                        style="background-color: #008400;"
                        >
                        <v-toolbar-title v-if="companyId===''"><v-icon style="margin-left:10px;margin-right:5px;margin-top:-5px;">mdi-factory</v-icon>Add Company</v-toolbar-title>
                        <v-toolbar-title v-else><v-icon style="margin-left:10px;margin-right:5px;margin-top:-5px;">mdi-factory</v-icon>Edit Company</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="editCompanyDialog = false; companyId='';"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <editCompany @closeDialog="loadCompanyData(); editCompanyDialog = false; companyId='';" @logoutOnError="logoutError" :id="companyId"></editCompany>
                        </v-card-text>
                    </v-card>
        </v-dialog>
	</div>
</template>

<script>
/*
				index:"company",
				epoch:epoch,
				companyName:"IoT X3 Pty Ltd",
				address:"15/128 Station Road",
				suburb:"Seven Hills",
				state:"NSW",
				postcode:"2147",
				country:"Australia",
				contact:"darren@iotx3.com",
				contactName:"Darren Russell",
				taxRate:10,
				taxRateName:"GST",
				businessId:[{abbreviation:"ABN",name:"Australian Business Number",id:"78629242520"},{abbreviation:"ACN",name:"Australian Company Number",id:"629242520"}],
				access:2,
                */
import data from '@/services/data.js'
import editCompany from '@/components/admin/editCompany.vue'
export default {
    name: 'company',
    components: {
        editCompany
    },
    // Bound properties declaration
    data() {
        return {
            headers: [
                { text: 'Company Name', value: 'companyName' },
                { text: 'Contact', value: 'contactName' },
                { text: 'Maximum Allowed Company Access', value: 'access' },
    			{ text: 'Actions', value: 'actions', sortable: false }
            ],
    		companies:[],
    		search:'',
    		selected:[],
            editCompanyDialog:false,
            companyId:"",
            accessLevels:[
                    {level:0,name:"User"},
                    {level:1,name:"Branch Administrator"},
                    {level:2,name:"Company Administrator"},
                    {level:3,name:"System Administrator"}
            ],
        };
    },
	methods: {
        logoutError()
        {
            this.$emit("logoutOnError");
        },
        editItem(item)
        {
            console.log("Set item "+item.id)
            this.companyId="";
            this.editCompanyDialog=true;
            this.companyId=item.id;
        },
        addCompany()
        {
            this.companyId="";
            this.editCompanyDialog=true;
        },
        deleteItem(item)
        {
            console.log(item);
        },
        getAccessLabel(accessLevel)
        {
            var result="";
            if (accessLevel.access>=0)
            {
                this.accessLevels.forEach(function(item)
                {
                    if (item.level===accessLevel.access)
                        result=item.name;

                });
            }
            return(result);
        },
        loadCompanyData()
        {
            console.log("load company data");
            data.getData("company",this.$store.state.access.token)
                .then(response=>{
                        if (response.data&&response.data.data&&response.data.token)
                        {
                            this.companies=response.data.data;
                            
                            this.$store.commit("setToken",response.data.token);
                        }
                        else 
                        {
                            if (response.data.result&&response.data.message&&response.data.result==="error")
                                {
                                    this.companies=[{id:"",companyName:"Error loading", contact:"company data", access:null}];
                                    console.log("logout on error Company");
                                    this.$emit("logoutOnError");
                                }
                        }
                    })
                .catch(error=> { 
                        console.log(error);
                        this.companies=[{id:"",companyName:"Error loading", contact:"company data", access:null}];
                    });
        }
	},
	computed: {
	},
  	created() 
	{
        this.loadCompanyData();
	},
};
</script>
